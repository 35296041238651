import { inject, Injectable } from '@angular/core';
import { CssToken } from './css.token';
import { WINDOW } from '@cca-environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  document = inject(DOCUMENT);
  window = inject(WINDOW);

  resolve(token: CssToken): string {
    if (!this.window) {
      return '';
    }

    const styles = this.window.getComputedStyle(this.document.documentElement);
    return styles.getPropertyValue(`--${token}`);
  }
}
