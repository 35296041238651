import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkTextLimiterPipe } from '@cca-common/cdk';

@Component({
  imports: [MatTooltipModule, CdkTextLimiterPipe],
  selector: 'cca-text',
  templateUrl: './text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTextComponent {
  _text = ' ';
  @Input() set text(value: string) {
    this._text = value ?? '';
  }
  get text() {
    return this._text;
  }
  @Input() maxLength = 24;
}
