/**
 * Task Management API
 * <p>The Task Management API is used to manage task board.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincargo.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Task priority  0 = Undefined  1 = None  2 = Low  3 = Medium  4 = High
 */
export enum TaskPriority {
  Undefined = 0,
  None = 1,
  Low = 2,
  Medium = 3,
  High = 4,
}
