import { Directive, OnDestroy, inject } from '@angular/core';
import {
  RouterLink,
  RouterLinkWithHref,
  RouterPreloader,
} from '@angular/router';
import { PreloadRegistryService } from './registry.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink]',
  standalone: true,
  host: {
    '(mouseenter)': 'listener ? listener() : null',
  },
})
export class PreloadLinkDirective implements OnDestroy {
  private routerLink =
    inject(RouterLink, { optional: true }) ||
    inject(RouterLinkWithHref, { optional: true });
  private registry = inject(PreloadRegistryService);
  private preloader = inject(RouterPreloader);

  // used in host element
  protected listener: (() => void) | null = this.onMouseOver.bind(this);

  onMouseOver() {
    // in older safari versions `requestIdleCallback` is undefined/does not exist adding a fallback here
    const callbackScheduler = requestIdleCallback ?? setTimeout;
    callbackScheduler(() => {
      const urlTree = this.routerLink?.urlTree;
      if (urlTree) {
        this.registry.add(urlTree);

        // auto completes so no need to unsubscribe or handle subscriptions for this
        this.preloader.preload().subscribe();
      }
    });
    this.removeListener();
  }

  removeListener() {
    this.listener = null;
  }

  ngOnDestroy() {
    this.removeListener();
  }
}
