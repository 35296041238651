import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import * as i1 from '@alyle/ui';
import { LyCommonModule } from '@alyle/ui';
const MEDIA_PRIORITY = 999;
const styles = {
  hide: {
    display: 'none'
  }
};
/**
 * @deprecated use `[lyDisplay]` instead.
 */
class MediaDirective {
  /**
   * Shows the item when the value is resolved as true
   */
  get lyShow() {
    return this._show;
  }
  set lyShow(val) {
    this._show = val;
    this._showClass = this.theme.addStyle(`lyMedia-show:${val}`, theme => ({
      [theme.getBreakpoint(val)]: {
        display: 'block'
      }
    }), this._elementRef.nativeElement, this._showClass, MEDIA_PRIORITY);
  }
  /**
   * Hides the item when the value is resolved as true
   */
  set lyHide(val) {
    this._hide = val;
    this._hideClass = this.theme.addStyle(`lyMedia-hide:${val}`, theme => ({
      [theme.getBreakpoint(val)]: {
        display: 'none'
      }
    }), this._elementRef.nativeElement, this._hideClass, MEDIA_PRIORITY);
  }
  get lyHide() {
    return this._hide;
  }
  constructor(_renderer, _elementRef, theme) {
    this._renderer = _renderer;
    this._elementRef = _elementRef;
    this.theme = theme;
    /**
     * Styles
     * @ignore
     */
    this.classes = this.theme.addStyleSheet(styles);
  }
  ngOnInit() {
    if (!this.lyHide) {
      this._renderer.addClass(this._elementRef.nativeElement, this.classes.hide);
    }
  }
  ngOnChanges() {
    if (this.lyHide && this.lyShow) {
      throw new Error(`use only \`lyHide\` or \`lyShow\` per element`);
    }
  }
  static {
    this.ɵfac = function MediaDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MediaDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.LyTheme2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MediaDirective,
      selectors: [["", "lyShow", ""], ["", "lyHide", ""]],
      inputs: {
        lyShow: "lyShow",
        lyHide: "lyHide"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MediaDirective, [{
    type: Directive,
    args: [{
      selector: '[lyShow], [lyHide]'
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i1.LyTheme2
  }], {
    lyShow: [{
      type: Input
    }],
    lyHide: [{
      type: Input
    }]
  });
})();

/**
 * @deprecated use instead `[lyDisplay]` or `[lyStyle]`
 */
class ResponsiveModule {
  static {
    this.ɵfac = function ResponsiveModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ResponsiveModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ResponsiveModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [LyCommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResponsiveModule, [{
    type: NgModule,
    args: [{
      declarations: [MediaDirective],
      exports: [MediaDirective, LyCommonModule]
    }]
  }], null, null);
})();
const Breakpoints = {
  XSmall: '(max-width: 599px)',
  Small: '(min-width: 600px) and (max-width: 959px)',
  Medium: '(min-width: 960px) and (max-width: 1279px)',
  Large: '(min-width: 1280px) and (max-width: 1919px)',
  XLarge: '(min-width: 1920px)',
  Handset: '(max-width: 599px) and (orientation: portrait), ' + '(max-width: 959px) and (orientation: landscape)',
  Tablet: '(min-width: 600px) and (max-width: 839px) and (orientation: portrait), ' + '(min-width: 960px) and (max-width: 1279px) and (orientation: landscape)',
  Web: '(min-width: 840px) and (orientation: portrait), ' + '(min-width: 1280px) and (orientation: landscape)',
  HandsetPortrait: '(max-width: 599px) and (orientation: portrait)',
  TabletPortrait: '(min-width: 600px) and (max-width: 839px) and (orientation: portrait)',
  WebPortrait: '(min-width: 840px) and (orientation: portrait)',
  HandsetLandscape: '(max-width: 959px) and (orientation: landscape)',
  TabletLandscape: '(min-width: 960px) and (max-width: 1279px) and (orientation: landscape)',
  WebLandscape: '(min-width: 1280px) and (orientation: landscape)'
};

/**
 * Generated bundle index. Do not edit.
 */

export { Breakpoints, MediaDirective, ResponsiveModule };
