import { marker as t } from '@jsverse/transloco-keys-manager/marker';

export enum FilterValueType {
  Default = 0,
  DateSpecificMonth = 1,
  DateToday = 2,
  DateNextXDays = 3,
  DateLastXDays = 4,
  DateTomorrow = 5,
}

export enum FilterDataType {
  boolean = 'Boolean',
  string = 'String',
  guid = 'Guid', // Guid datatype behaves the same as string. The Back-end property is a guid, but is resolved in an external service using a text filter
  number = 'Int',
  dateTime = 'DateTime',
  date = 'Date',
}

export enum FilterConditionType {
  equals = 'equals',
  notEquals = 'notEquals',
  contains = 'contains',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
  between = 'between',
}

export function filterConditionTypeMapper(condition: FilterConditionType) {
  switch (condition) {
    case FilterConditionType.between:
      return t('datatable.filters.condition.between');
    case FilterConditionType.equals:
      return t('datatable.filters.condition.equals');
    case FilterConditionType.notEquals:
      return t('datatable.filters.condition.notEquals');
    case FilterConditionType.contains:
      return t('datatable.filters.condition.contains');
    case FilterConditionType.greaterThan:
      return t('datatable.filters.condition.greaterThan');
    case FilterConditionType.lessThan:
      return t('datatable.filters.condition.lessThan');
    default:
      return t('datatable.filters.condition.unknown');
  }
}

export const booleanConditions: FilterConditionType[] = [
  FilterConditionType.equals,
];

export const stringConditions: FilterConditionType[] = [
  FilterConditionType.equals,
  FilterConditionType.notEquals,
  FilterConditionType.contains,
];
export const numberConditions: FilterConditionType[] = [
  FilterConditionType.equals,
  FilterConditionType.notEquals,
  FilterConditionType.greaterThan,
  FilterConditionType.lessThan,
  // FilterConditionType.between,
];

export const choiceConditions: FilterConditionType[] = [
  FilterConditionType.equals,
  FilterConditionType.notEquals,
];

export const dateTimeConditions: FilterConditionType[] = [
  FilterConditionType.greaterThan,
  FilterConditionType.lessThan,
  // FilterConditionType.between,
];

export const dateConditions: FilterConditionType[] = numberConditions;

export const filterAllTextValue = 'AllText';

export interface FilterInfo {
  uniqueKey: string;
  filterDefinitions: FilterDefinition[];
}

export interface FilterDefinition {
  dataType: FilterDataType;
  filterKey: string;
  filterName: string;
  filterChoices: FilterChoiceOption[];
  isChoice: boolean;
  choiceOptionsIncludedByDefault?: boolean;
}

export interface FilterChoiceOption {
  key: string;
  name: string;
}

export interface Filter {
  filterKey: string;
  dataType?: string | null;
  condition: FilterConditionType;
  filterValueType?: FilterValueType;
  values: string[];
}

export interface FilterSet {
  id: number | string;
  filters: Filter[];
}
