/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Actions  2 = Road  3 = ShortSea  4 = Train  5 = CustomActions
 */
export enum ModalityType {
  Undefined = 0,
  Actions = 1,
  Road = 2,
  ShortSea = 3,
  Train = 4,
  CustomActions = 5,
}
