/**
 * Legal Comliance API
 * <p>The Legal Compliance API is used to manage Contractual Agreements.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincargo.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = CCATermsOfUse  2 = ShipperTermsAndConditions  3 = CarrierTermsAndConditions  4 = ManagedTransTermsAndConditions
 */
export enum LegalDocumentType {
  Undefined = 0,
  CCATermsOfUse = 1,
  ShipperTermsAndConditions = 2,
  CarrierTermsAndConditions = 3,
  ManagedTransTermsAndConditions = 4,
}
