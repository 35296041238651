import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  SimpleTaskViewModel,
  TaskState,
  TaskDetailViewModel,
  ReadTasksRequest,
  FilterChoiceOption,
  FilterSetViewModel,
  TaskCommentViewModel,
  UpdateTaskCommentRequest,
} from '../model';
import { id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends BaseApiService {
  constructor() {
    super('task.v1.task');
  }

  getTasks(filterData: ReadTasksRequest) {
    return this.http.post<SimpleTaskViewModel[]>(
      `${this.url}get-tasks`,
      filterData,
    );
  }

  getTaskDetails(taskId: id) {
    return this.http.get<TaskDetailViewModel>(
      `${this.url}get-task-details/${taskId}`,
    );
  }

  updateTaskState(taskId: id, newTaskState: TaskState) {
    return this.http.put<boolean>(`${this.url}update-task-status`, {
      id: taskId,
      status: newTaskState,
    });
  }

  closeTask(taskId: id) {
    return this.http.put<boolean>(`${this.url}close-task`, {
      id: taskId,
    });
  }

  getFilters() {
    return this.http.get<FilterSetViewModel>(`${this.url}filters`);
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  readTaskComments(taskId: id) {
    return this.http.post<TaskCommentViewModel[]>(
      `${this.url}read-task-comments`,
      {
        taskId: taskId,
      },
    );
  }

  updateTaskComment(request: UpdateTaskCommentRequest) {
    return this.http.put<TaskCommentViewModel>(
      `${this.url}update-task-comment`,
      request,
    );
  }

  addTaskComment(taskId: id, comment: string) {
    return this.http.post<TaskCommentViewModel>(`${this.url}add-task-comment`, {
      taskId: taskId,
      comment: comment,
    });
  }
}
