import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { RespondToLaneTenderV2Request } from '../model';

@Injectable({
  providedIn: 'root',
})
export class TenderLaneV2Service extends BaseApiService {
  constructor() {
    super(`tender.v2.lane`);
  }

  respond(request: RespondToLaneTenderV2Request) {
    return this.http.post(`${this.url}respond`, request);
  }

  respondUnauthorized(request: RespondToLaneTenderV2Request) {
    return this.http.post(`${this.url}respond-unauthorized`, request);
  }
}
