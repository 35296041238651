import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { CountryFlagComponent } from '../country-flag';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, CountryFlagComponent, NgClass],
})
export class ContactComponent {
  @Input() noContact = false;
  @Input() noContactText = '';
  @Input() fullName: string | undefined | null;
  @Input() phoneNumber: string | undefined | null;
  @Input() avatarURL: string | null | undefined = '';
  @Input() responsibilities: string | undefined | null;
  @Input() email: string | undefined | null;
  @Input() flagImageUrl: string | undefined | null;
  @Input() enterpriseName: string | undefined | null;
  @Input() groupName: string | undefined | null;

  get initials(): string {
    if (!this.fullName) return '';
    if (this.fullName) {
      return this.fullName
        .split(' ')
        .map((word) => word[0])
        .filter(
          (letter, _, initials) =>
            initials.indexOf(letter) === 0 ||
            initials.indexOf(letter) === initials.length - 1,
        )
        .join('')
        .toUpperCase();
    }
    return '';
  }
}
