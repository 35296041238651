/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Weekly  2 = Monthly  3 = Quarterly  4 = SemiAnnually  5 = Annually
 */
export enum ShipmentFrequencyOptions {
  Undefined = 0,
  Weekly = 1,
  Monthly = 2,
  Quarterly = 3,
  SemiAnnually = 4,
  Annually = 5,
}
