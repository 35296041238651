import { Color, hexColorToInt } from '@alyle/ui/color';
import * as i0 from '@angular/core';
import { InjectionToken, Directive, Input, NgModule, ElementRef, isDevMode, ViewEncapsulation, Injectable, Inject, Optional, Component, HostListener, Injector, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import * as i1 from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Subject, ReplaySubject, Subscription, merge, fromEvent, empty } from 'rxjs';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { __decorate } from 'tslib';
import { takeUntil, auditTime, map, share } from 'rxjs/operators';
import { HammerGestureConfig } from '@angular/platform-browser';
import * as i2 from '@angular/cdk/scrolling';
function getContrastYIQ(hexcolor) {
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}
const shadowKeyUmbraOpacity = 0.2;
const shadowKeyPenumbraOpacity = 0.14;
const shadowAmbientShadowOpacity = 0.12;
const Shadows = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 1, 3, 0, 0, 1, 1, 0, 0, 2, 1, -1], [0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2], [0, 1, 8, 0, 0, 3, 4, 0, 0, 3, 3, -2], [0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0], [0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0], [0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0], [0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1], [0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2], [0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2], [0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3], [0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3], [0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4], [0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4], [0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4], [0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5], [0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5], [0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5], [0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6], [0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6], [0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7], [0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7], [0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7], [0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8], [0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8]];
function shadowBuilder(elevation, color) {
  let _color = color || new Color(0, 0, 0);
  const rgb = _color.rgba();
  if (!(rgb[0] === rgb[1] && rgb[0] === rgb[2])) {
    // Darken and saturate if the color is not in the grayscale
    _color = _color.darken().saturate(2);
  }
  const colors = [_color.alpha(shadowKeyUmbraOpacity).css(), _color.alpha(shadowKeyPenumbraOpacity).css(), _color.alpha(shadowAmbientShadowOpacity).css()];
  const e = Shadows[elevation];
  // tslint:disable-next-line:max-line-length
  return `${e[0]}px ${e[1]}px ${e[2]}px ${e[3]}px ${colors[0]},${e[4]}px ${e[5]}px ${e[6]}px ${e[7]}px ${colors[1]},${e[8]}px ${e[9]}px ${e[10]}px ${e[11]}px ${colors[2]}`;
}
const THEME_VARIABLES = new InjectionToken('ly.theme.variables');
const IS_CORE_THEME = new InjectionToken('ly.is.root');

// Whether the current platform supports the V8 Break Iterator. The V8 check
// is necessary to detect all Blink based browsers.
const hasV8BreakIterator = typeof Intl !== 'undefined' && Intl.v8BreakIterator;
/**
 * Service to detect the current platform by comparing the userAgent strings and
 * checking browser-specific global properties.
 * @deprecated Use Angular CDK instead
 * `import { Platform } from '@angular/cdk/platform'`
 */
class Platform {
  static {
    this.isBrowser = typeof document === 'object' && !!document;
  }
  /** Layout Engines */
  static {
    this.EDGE = Platform.isBrowser && /(edge)/i.test(navigator.userAgent);
  }
  static {
    this.TRIDENT = Platform.isBrowser && /(msie|trident)/i.test(navigator.userAgent);
  }
  // EdgeHTML and Trident mock Blink specific things and need to be excluded from this check.
  static {
    this.BLINK = Platform.isBrowser && !!(window.chrome || hasV8BreakIterator) && !!CSS && !Platform.EDGE && !Platform.TRIDENT;
  }
  // Webkit is part of the userAgent in EdgeHTML, Blink and Trident. Therefore we need to
  // ensure that Webkit runs standalone and is not used as another engine's base.
  static {
    this.WEBKIT = Platform.isBrowser && /AppleWebKit/i.test(navigator.userAgent) && !Platform.BLINK && !Platform.EDGE && !Platform.TRIDENT;
  }
  /** Browsers and Platform Types */
  static {
    this.IOS = Platform.isBrowser && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  // It's difficult to detect the plain Gecko engine, because most of the browsers identify
  // them self as Gecko-like browsers and modify the userAgent's according to that.
  // Since we only cover one explicit Firefox case, we can simply check for Firefox
  // instead of having an unstable check for Gecko.
  static {
    this.FIREFOX = Platform.isBrowser && /(firefox|minefield)/i.test(navigator.userAgent);
  }
  // Trident on mobile adds the android platform to the userAgent to trick detections.
  static {
    this.ANDROID = Platform.isBrowser && /android/i.test(navigator.userAgent) && !Platform.TRIDENT;
  }
  // Safari browsers will include the Safari keyword in their userAgent. Some browsers may fake
  // this and just place the Safari keyword in the userAgent. To be more safe about Safari every
  // Safari browser should also use Webkit as its layout engine.
  static {
    this.SAFARI = Platform.isBrowser && /safari/i.test(navigator.userAgent) && Platform.WEBKIT;
  }
}
let supportsPassive;
function supportsPassiveEventListeners() {
  if (supportsPassive === void 0) {
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          supportsPassive = true;
        }
      });
      window.addEventListener('testPassive', null, opts);
      window.removeEventListener('testPassive', null, opts);
    } catch (e) {}
  }
  return supportsPassive;
}
class NgTranscludeDirective {
  set ngTransclude(templateRef) {
    if (templateRef && !this._ngTransclude) {
      if (this._platform.isBrowser && this._timeoutId != null) {
        window.clearTimeout(this._timeoutId);
        this._timeoutId = null;
        this.vcr.clear();
      }
      this._ngTransclude = templateRef;
      this.vcr.createEmbeddedView(templateRef);
    } else if (this._ngTransclude && !templateRef) {
      this._ngTransclude = null;
      this.clear();
    }
  }
  get getNgTransclude() {
    return this._ngTransclude;
  }
  constructor(vcr, _platform) {
    this.vcr = vcr;
    this._platform = _platform;
  }
  clear() {
    if (this._platform.isBrowser && this.timeout) {
      this._timeoutId = window.setTimeout(() => {
        this.vcr.clear();
        this._timeoutId = null;
      }, this.timeout);
    } else {
      this.vcr.clear();
    }
  }
  ngOnDestroy() {
    if (this._platform.isBrowser) {
      window.clearTimeout(this._timeoutId);
    }
    this.vcr.clear();
  }
  static {
    this.ɵfac = function NgTranscludeDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgTranscludeDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i1.Platform));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgTranscludeDirective,
      selectors: [["", "ngTransclude", ""]],
      inputs: {
        timeout: "timeout",
        ngTransclude: "ngTransclude"
      },
      exportAs: ["ngTransclude"],
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgTranscludeDirective, [{
    type: Directive,
    args: [{
      selector: '[ngTransclude]',
      exportAs: 'ngTransclude'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }, {
    type: i1.Platform
  }], {
    timeout: [{
      type: Input
    }],
    ngTransclude: [{
      type: Input
    }]
  });
})();
class NgTranscludeModule {
  static {
    this.ɵfac = function NgTranscludeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgTranscludeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgTranscludeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgTranscludeModule, [{
    type: NgModule,
    args: [{
      exports: [NgTranscludeDirective],
      declarations: [NgTranscludeDirective]
    }]
  }], null, null);
})();
/**
 * @ignore
 */
function getNativeElement(element) {
  return element instanceof ElementRef ? element.nativeElement : element;
}
const DEFAULT_VALUE = '';
const STYLE_PRIORITY$2 = -1;
function mixinStyleUpdater(base) {
  return class extends base {
    setAutoContrast() {
      this._autoContrast = true;
    }
    updateStyle(element) {
      const __bg = this._superHyperInternalPropertyBg;
      const __color = this._superHyperInternalPropertyColor === 'auto' ? '' : this._superHyperInternalPropertyColor;
      const __raised = this._superHyperInternalPropertyRaised;
      const __elevation = this._superHyperInternalPropertyElevation;
      const __disabled = this._superHyperInternalPropertyDisabled;
      const __outlined = this._superHyperInternalPropertyOutlined;
      const __shadowColor = this._superHyperInternalPropertyShadowColor;
      const __isContrast = this._autoContrast || this._superHyperInternalPropertyColor === 'auto';
      const el = getNativeElement(element);
      const newKey = `c--${__bg || DEFAULT_VALUE}_${__color || DEFAULT_VALUE}_${__raised || DEFAULT_VALUE}_${__elevation || DEFAULT_VALUE}_${__disabled || DEFAULT_VALUE}_${__outlined || DEFAULT_VALUE}_${__shadowColor || DEFAULT_VALUE}_${__isContrast || DEFAULT_VALUE}`;
      const newClass = this._theme.renderStyle(newKey, theme => {
        let sColor;
        let sBackground;
        let sBorder;
        let sPointerEvents;
        let sBoxShadow;
        let sBoxShadowActive;
        if (__outlined) {
          sBorder = '1px solid currentColor';
        }
        if (__disabled) {
          sColor = theme.disabled.contrast;
          sPointerEvents = 'none';
          if (__bg || __raised) {
            sBackground = theme.disabled.default;
          }
        } else {
          if (__bg) {
            sBackground = colorOf(theme, __bg);
            if (__isContrast && !__color) {
              sColor = theme.colorOf(`${__bg}:contrast`);
              // Generate auto contrast if is necessary
              if (sColor.css().includes('invalid')) {
                const lum = (__bg instanceof Color ? __bg : theme.colorOf(__bg)).luminance();
                sColor = lum < 0.5 ? theme.text.light : theme.text.dark;
              }
            }
          }
          if (!sColor && __color) {
            sColor = colorOf(theme, __color);
          }
          if (__raised || __elevation != null) {
            if (!__bg) {
              sBackground = theme.background.primary.default;
            }
            const backgroundColorCss = sBackground !== __bg && colorOf(theme, __bg || 'background:primary', 'shadow');
            const shadowColor = __shadowColor && colorOf(theme, __shadowColor) || backgroundColorCss || sBackground || sColor || theme.shadow;
            if (__elevation != null) {
              sBoxShadow = shadowBuilder(__elevation, shadowColor);
            } else {
              sBoxShadow = shadowBuilder(3, shadowColor);
              sBoxShadowActive = shadowBuilder(8, shadowColor);
            }
          }
        }
        return _className => `${_className}{${sColor ? 'color:' + sColor : ''};${sBackground ? 'background:' + sBackground : ''};${sBorder ? 'border:' + sBorder : ''};${sPointerEvents ? 'pointer-events:' + sPointerEvents : ''};${sBoxShadow ? 'box-shadow:' + sBoxShadow : ''};}${_className}:active{${sBoxShadowActive ? 'box-shadow:' + sBoxShadowActive : ''};}`;
      }, STYLE_PRIORITY$2);
      el.classList.remove(this._classNameAnonymous);
      el.classList.add(newClass);
      this._classNameAnonymous = newClass;
    }
    constructor(...args) {
      super(...args);
    }
  };
}
function colorOf(theme, color, optional) {
  return color instanceof Color ? color : theme.colorOf(color, optional);
}
class RippleRef {
  constructor() {
    this.state = true;
    this.timestamp = -Date.now();
    this.container = document.createElement('span');
  }
  end() {
    this.state = false;
    this.timestamp += Date.now();
  }
}
class Ripple {
  constructor(_themeVariables, _ngZone, classes, _containerElement, platform, _triggerElement) {
    this._themeVariables = _themeVariables;
    this._ngZone = _ngZone;
    this.classes = classes;
    this._containerElement = _containerElement;
    this._triggerElement = _triggerElement;
    this._eventHandlers = new Map();
    this.config = {};
    this._transitionDuration = this._themeVariables.ripple.duration;
    this._eventOptions = {
      passive: true
    };
    if (platform.isBrowser) {
      if (typeof PointerEvent === 'function' && typeof TouchEvent === 'function') {
        this._eventHandlers.set('pointerdown', this.onPointerDown.bind(this));
      } else {
        this._eventHandlers.set('mousedown', this.onPointerDown.bind(this));
      }
      this._eventHandlers.set('touchend', this.onPointerLeave.bind(this));
      this._eventHandlers.set('touchcancel', this.onPointerLeave.bind(this));
      this._eventHandlers.set('mouseup', this.onPointerLeave.bind(this));
      this._eventHandlers.set('mouseleave', this.onPointerLeave.bind(this));
      if (!_triggerElement) {
        _triggerElement = _containerElement;
      }
      this.setTriggerElement(_triggerElement);
    }
  }
  setConfig(config) {
    this.config = config;
  }
  get _rectContainer() {
    return this._containerElement.getBoundingClientRect();
  }
  setTriggerElement(element) {
    if (element) {
      this._ngZone.runOutsideAngular(() => {
        this._eventHandlers.forEach((fn, type) => element.addEventListener(type, fn, this._eventOptions));
      });
    }
    this._triggerElement = element;
  }
  createRipple(styles) {
    this._rippleRef = new RippleRef();
    const container = this._rippleRef.container;
    container.className = this.classes.rippleContainer;
    for (const key in styles) {
      if (styles.hasOwnProperty(key)) {
        const element = styles[key];
        if (typeof element === 'number') {
          container.style[key] = `${element}px`;
        } else {
          container.style[key] = element;
        }
      }
    }
    this._containerElement.appendChild(container);
    window.getComputedStyle(container).getPropertyValue('opacity');
    container.style.transform = `scale(1)`;
  }
  onPointerDown(event) {
    if (!this.config.disabled) {
      /**Destroy previous ripple if exist */
      this.endRipple();
      this.startRipple(event, this.config);
    }
  }
  onPointerLeave(_event) {
    if (!this.config.disabled) {
      this.endRipple();
    }
  }
  startRipple(event, rippleConfig) {
    const containerRect = this._rectContainer;
    let x = event.clientX,
      y = event.clientY;
    if (rippleConfig.centered) {
      x = containerRect.left + containerRect.width / 2;
      y = containerRect.top + containerRect.height / 2;
    }
    const left = x - containerRect.left;
    const top = y - containerRect.top;
    let radius = rippleConfig.radius === 'containerSize' ? maxSize(containerRect) / 2 : rippleConfig.radius || rippleRadius(x, y, containerRect);
    if (rippleConfig.percentageToIncrease) {
      radius += radius * rippleConfig.percentageToIncrease / 100;
    }
    this.createRipple({
      left: left - radius,
      top: top - radius,
      width: radius * 2,
      height: radius * 2,
      transitionDuration: `${this._transitionDuration}ms`
    });
  }
  runTimeoutOutsideZone(fn, delay = 0) {
    this._ngZone.runOutsideAngular(() => setTimeout(fn, delay));
  }
  endRipple() {
    const rippleRef = this._rippleRef;
    const duration = this._transitionDuration;
    if (rippleRef && rippleRef.state) {
      rippleRef.end();
      this.runTimeoutOutsideZone(() => {
        rippleRef.container.style.opacity = '0';
        rippleRef.container.style.transitionDuration = `${this._transitionDuration / 5}ms`;
        // }, rippleRef.timestamp < duration ? duration : 0);
        // }, rippleRef.timestamp < duration ? duration / (duration * .001 + 1) : 0);
      }, rippleRef.timestamp < duration ? duration * .15 : 0);
      this.runTimeoutOutsideZone(() => {
        rippleRef.container.parentNode.removeChild(rippleRef.container);
        // }, rippleRef.timestamp < duration ? duration * 2 : duration);
        // }, rippleRef.timestamp < duration ? duration / (duration * .001 + 1) * 2 : duration);
      }, rippleRef.timestamp < duration ? duration * 2 : duration);
      this._rippleRef = undefined;
    }
  }
  removeEvents() {
    if (this._triggerElement) {
      this._eventHandlers.forEach((fn, type) => {
        this._triggerElement.removeEventListener(type, fn, this._eventOptions);
      });
    }
  }
}
function rippleRadius(x, y, rect) {
  const distX = Math.max(Math.abs(x - rect.left), Math.abs(x - rect.right));
  const distY = Math.max(Math.abs(y - rect.top), Math.abs(y - rect.bottom));
  return Math.sqrt(distX * distX + distY * distY);
}
function maxSize(rect) {
  return Math.max(rect.width, rect.height);
}
const LY_THEME_GLOBAL_VARIABLES = new InjectionToken('ly.theme.global.variables');
const LY_THEME = new InjectionToken('ly_theme_config');
const LY_THEME_NAME = new InjectionToken('ly.theme.name');
const LY_ENABLE_SELECTORS_FN = new InjectionToken('LY_ENABLE_SELECTORS_FN');
/**
 * Add a prefix to the class name that will be generated for styles.
 * Only works in production mode.
 */
const LY_CLASS_NAME_PREFIX = new InjectionToken('LY_CLASS_NAME_PREFIX');
function memoize(fn) {
  const cache = {};
  return key => {
    if (cache[key] === undefined) {
      cache[key] = fn(key);
    }
    return cache[key];
  };
}

/**
 * For internal use only
 * @docsPrivate
 */
const _STYLE_MAP = new Map();
var TypeStyle;
(function (TypeStyle) {
  TypeStyle[TypeStyle["Multiple"] = 0] = "Multiple";
  TypeStyle[TypeStyle["OnlyOne"] = 1] = "OnlyOne";
  /**
   * A lyl Style
   */
  TypeStyle[TypeStyle["LylStyle"] = 2] = "LylStyle";
})(TypeStyle || (TypeStyle = {}));
const getThemeNameForSelectors = memoize(themeId => {
  return `${themeId}<~(selectors)`;
});
const LINE_FEED_REGEX = () => /[\n\s]*([^\n]+)/g;
const AMPERSAND_REGEX = () => /&/g;
var RuleType;
(function (RuleType) {
  RuleType[RuleType["FontFace"] = 0] = "FontFace";
  RuleType[RuleType["MediaQuery"] = 1] = "MediaQuery";
  RuleType[RuleType["KeyFrame"] = 2] = "KeyFrame";
  RuleType[RuleType["Style"] = 3] = "Style";
  RuleType[RuleType["DynamicStyle"] = 4] = "DynamicStyle";
  RuleType[RuleType["CompiledStyle"] = 5] = "CompiledStyle";
})(RuleType || (RuleType = {}));
/**
 * Transform a lyl style block to CSS
 */
class LylParse {
  constructor(_template, _className = '${_className}', _isServer, /** Is true when used inside lyl */
  _isDevMode) {
    this._template = _template;
    this._className = _className;
    this._isServer = _isServer;
    this._isDevMode = _isDevMode;
  }
  toCss() {
    const selectors = [];
    const resolvedSelectors = [];
    const rules = [];
    this._template.replace(/(\/\/\s[^\n\r]*(?:[\n\r]+|$))/g, '') // remove comments
    .replace(/,[\n\s]+/g, ',').replace(LINE_FEED_REGEX(), (_ex, fullLine) => {
      if (fullLine.endsWith('{')) {
        if (selectors.length === 0) {
          selectors.push([this._className]);
          this._createRule(rules, RuleType.Style, selectors, resolvedSelectors);
        } else {
          const line_1 = fullLine.slice(0, fullLine.length - 1).trim();
          if (line_1 === '@font-face') {
            selectors.push(line_1);
            this._createRule(rules, RuleType.FontFace, selectors, resolvedSelectors);
          } else if (line_1.startsWith('@m')) {
            selectors.push(line_1);
            this._createRule(rules, RuleType.MediaQuery, selectors, resolvedSelectors);
          } else if (line_1.startsWith('@k')) {
            selectors.push(line_1);
            this._createRule(rules, RuleType.KeyFrame, selectors, resolvedSelectors);
          } else {
            selectors.push(line_1.split(',').map(_ => _.trim()));
            this._createRule(rules, RuleType.Style, selectors, resolvedSelectors);
          }
        }
      } else if (fullLine.length === 1 && fullLine === '}') {
        this._removeParentSelector(rules, selectors, resolvedSelectors);
      } else if (this._isDevMode && fullLine.startsWith('/* >> ds')) {
        const lin = fullLine;
        this._createRuleWithResolvedSelector(rules, RuleType.DynamicStyle, selectors, resolvedSelectors, lin);
      } else if (this._isServer && fullLine.startsWith('...')) {
        const content = fullLine.slice(3);
        this._createRuleWithResolvedSelector(rules, RuleType.CompiledStyle, selectors, resolvedSelectors, content);
      } else {
        if (fullLine) {
          if (this._isDevMode && fullLine.includes('undefined')) {
            return '';
          }
          if (this._isDevMode && fullLine.endsWith(';')) {
            throw new Error(`Do not require semicolon in [${fullLine}]`);
          }
          if (fullLine.includes(': ')) {
            fullLine = fullLine.replace(': ', ':');
          }
          fullLine += ';';
          this._appendDeclaration(rules, RuleType.Style, fullLine);
        }
      }
      return '';
    });
    return rules.filter(rule => !rule.isEmpty).map(rule => {
      return rule.block;
    }).join('');
  }
  _createRule(rules, type, selectors, resolvedSelectors) {
    const parentRule = resolvedSelectors[resolvedSelectors.length - 1];
    const prevRule = rules[rules.length - 1];
    if (prevRule && !prevRule.isReady) {
      prevRule.block += `}`;
      prevRule.isReady = true;
    }
    let selector = '';
    if (type === RuleType.FontFace) {
      selector = `@font-face`;
    } else if (type === RuleType.KeyFrame) {
      selector = selectors[1];
    } else if (parentRule && parentRule.type === RuleType.KeyFrame) {
      selector = selectors[selectors.length - 1][0];
    } else if (type === RuleType.MediaQuery || parentRule && parentRule.type === RuleType.MediaQuery && prevRule && prevRule.isEmpty) {
      selector = resolveSelectors(selectors, false);
    } else {
      selector = resolveSelectors(selectors, true);
    }
    const rule = {
      selector,
      block: `${selector}{`,
      type: RuleType.Style,
      isEmpty: true
    };
    resolvedSelectors.push({
      selector: '',
      block: '',
      type: type,
      isEmpty: true
    });
    rules.push(rule);
    // If is new media query
    if (type === RuleType.MediaQuery) {
      // rule.block += `{`;
      rule.hasMedia = true;
    }
    if (parentRule && parentRule.hasMedia) {
      rule.hasMedia = true;
    }
    if (type === RuleType.KeyFrame) {
      rule.isReady = true;
      rule.isEmpty = false;
    }
    return rule;
  }
  _createRuleWithResolvedSelector(rules, type, selectors, resolvedSelectors, content) {
    if (!content) {
      return;
    }
    const prevRule = rules[rules.length - 1];
    const parentRule = resolvedSelectors[resolvedSelectors.length - 1];
    const {
      hasMedia
    } = prevRule;
    let selector = '';
    if (type === RuleType.MediaQuery || parentRule && parentRule.type === RuleType.MediaQuery && prevRule && prevRule.isEmpty) {
      selector = resolveSelectors(selectors, false);
    } else {
      selector = resolveSelectors(selectors, true);
    }
    // Close previous
    if (prevRule && !prevRule.isReady) {
      prevRule.block += `}`;
    }
    const rule = {
      selector: selector,
      block: ``,
      type,
      hasMedia,
      isEmpty: false
    };
    if (type === RuleType.CompiledStyle) {
      rule.block = transformCC(content, selector);
    } else if (type === RuleType.DynamicStyle) {
      rule.block = content.replace(/\|\|\&\|\|/g, selector);
    }
    rule.isReady = true;
    rules.push(rule);
  }
  _appendDeclaration(rules, _type, content) {
    if (!content) {
      return;
    }
    let prevRule = rules[rules.length - 1];
    if (prevRule && prevRule.isReady) {
      prevRule = {
        selector: prevRule.selector,
        block: `${prevRule.selector}{`,
        type: prevRule.type,
        hasMedia: prevRule.hasMedia,
        isEmpty: false
      };
      rules.push(prevRule);
    }
    prevRule.block += content;
    prevRule.isEmpty = false;
  }
  _removeParentSelector(rules, selectors, resolvedSelectors) {
    selectors.pop();
    const prevRule = rules[rules.length - 1];
    const currentRule = resolvedSelectors.pop();
    if (!prevRule) {
      return;
    }
    // Close previous rule
    if (!prevRule.isReady) {
      prevRule.block += `}`;
      prevRule.isReady = true;
    }
    if (currentRule && (currentRule.type === RuleType.MediaQuery || currentRule.type === RuleType.KeyFrame)) {
      prevRule.block += `}`;
      prevRule.isReady = true;
    }
  }
}
function transformCC(content, sel) {
  content = content.replace(/\/\* >> cc[^\/\*]+\*\//g, '');
  let expression = content.slice(2, content.length - 1);
  expression = `st2c((${expression}), \`${sel}\`)`;
  return `\${${expression}}`;
}
function resolveSelectors(selectors, ignoreMediaQuery) {
  let media = null;
  const sel = selectors.filter(_ => {
    if (typeof _ === 'string') {
      if (!ignoreMediaQuery) {
        media = _;
      }
      return false;
    }
    return !!_.filter(__ => __).length;
  });
  const sel2 = sel.length === 1 ? sel[0].join(',') : sel.length ? sel.reduce((prv, curr) => {
    const result = prv.map(item => curr.map(cu => {
      if (cu.includes('&')) {
        return cu.replace(AMPERSAND_REGEX(), item);
      }
      return `${item} ${cu}`;
    }));
    return Array.prototype.concat.apply([], result);
  }).join(',') : '';
  if (media) {
    return `${media}{${sel2}`;
  }
  return sel2;
}
function lyl(literals, ...placeholders) {
  return className => {
    let result = '';
    // Save expressions
    for (let i = 0; i < placeholders.length; i++) {
      const placeholder = placeholders[i];
      result += literals[i];
      if (result.endsWith('...')) {
        result = result.slice(0, result.length - 3);
        if (typeof placeholder === 'function' || placeholder instanceof StyleCollection) {
          result += `${createUniqueCommentSelector('ds')}${st2c(placeholder, '||&||')}`;
        }
      } else {
        result += placeholder;
      }
    }
    // add the last literal
    result += literals[literals.length - 1];
    const css = new LylParse(result, className, false, true).toCss();
    return css;
  };
}
function createUniqueCommentSelector(text = 'id') {
  return `/* >> ${text} -- ${Math.floor(new Date().valueOf() * Math.random()).toString(36)} */`;
}
class StyleCollection {
  constructor(...templates) {
    this._templates = templates;
    this.css = this.css.bind(this);
  }
  add(...templates) {
    // return new StyleCollection(...[...this._templates, ...templates]);
    this._templates.push(...templates);
    return this;
  }
  /** Transform style */
  setTransformer(transformer) {
    this._transformer = transformer;
    return this;
  }
  /**
   * @return StyleTemplate
   * @docs-private
   */
  css(className) {
    let lin = '';
    const templates = this._templates;
    for (let index = 0; index < templates.length; index++) {
      let template;
      if (this._transformer) {
        template = this._transformer(templates[index]);
      } else {
        template = templates[index];
      }
      lin += template(className);
    }
    return lin;
  }
}
/**
 * Transform a ...{style} to css
 * For internal use purposes only
 * @param fn StyleTemplate or StyleCollection
 * @param className class name
 */
function st2c(fn, className) {
  if (fn == null) {
    return '';
  }
  if (fn instanceof StyleCollection) {
    return fn.css(className);
  }
  return fn(className);
}
class StringIdGenerator {
  constructor(chars = 'abcdefghijklmnopqrstuvwxyz') {
    this._chars = chars;
    this._nextId = [0];
  }
  next() {
    const r = [];
    for (const char of this._nextId) {
      r.unshift(this._chars[char]);
    }
    this._increment();
    return r.join('');
  }
  _increment() {
    for (let i = 0; i < this._nextId.length; i++) {
      const val = ++this._nextId[i];
      if (val >= this._chars.length) {
        this._nextId[i] = 0;
      } else {
        return;
      }
    }
    this._nextId.push(0);
  }
}
class LyStyleUtils {
  constructor() {
    /** Returns top */
    this.above = 'top';
    /** Returns bottom */
    this.below = 'bottom';
  }
  /** Returns left or right according to the direction */
  get before() {
    return this.getDirection(DirAlias.before);
  }
  /** Returns left or right according to the direction */
  get after() {
    return this.getDirection(DirAlias.after);
  }
  pxToRem(value) {
    const size = this.typography.fontSize / 14;
    return `${value / this.typography.htmlFontSize * size}rem`;
  }
  colorOf(value, optional) {
    if (typeof value === 'number') {
      return new Color(value);
    }
    if (value[0] === '#' && value.length === 7) {
      return new Color(hexColorToInt(value));
    }
    const color = get(this, value, optional);
    if (color) {
      return color;
    }
    /** Create invalid color */
    return new Color();
  }
  getBreakpoint(key) {
    return `@media ${this.breakpoints[key] || key}`;
  }
  selectorsOf(styles) {
    const styleMap = _STYLE_MAP.get(styles);
    if (styleMap) {
      return styleMap.classes || styleMap[this.name];
    } else {
      throw Error('Classes not found');
    }
  }
  getDirection(val) {
    if (val === DirAlias.before) {
      return this.direction === 'rtl' ? 'right' : 'left';
    } else if (val === DirAlias.after) {
      return this.direction === 'rtl' ? 'left' : 'right';
    } else if (val === 'above') {
      return 'top';
    } else if (val === 'below') {
      return 'bottom';
    }
    return val;
  }
  isRTL() {
    return this.direction === Dir.rtl;
  }
}
var Dir;
(function (Dir) {
  Dir["rtl"] = "rtl";
  Dir["ltr"] = "ltr";
})(Dir || (Dir = {}));
var DirAlias;
(function (DirAlias) {
  DirAlias["before"] = "before";
  DirAlias["after"] = "after";
})(DirAlias || (DirAlias = {}));
var DirPosition;
(function (DirPosition) {
  DirPosition["left"] = "left";
  DirPosition["right"] = "right";
})(DirPosition || (DirPosition = {}));
/**
 * get color of object
 * @param obj object
 * @param path path
 * @param optional get optional value, if not exist return default if not is string
 */
function get(obj, path, optional) {
  if (path === 'transparent') {
    return new Color(0, 0, 0, 0);
  }
  const _path = path instanceof Array ? path : path.split(':');
  for (let i = 0; i < _path.length; i++) {
    const posibleOb = obj[_path[i]];
    if (posibleOb) {
      obj = posibleOb;
    } else {
      /** if not exist */
      return new Color();
    }
  }
  if (obj instanceof Color) {
    return obj;
  } else if (optional) {
    return obj[optional] ?? obj['default'];
  } else {
    return obj['default'];
  }
  // return typeof obj === 'string' ? obj as string : obj['default'] as string;
}
/**
 * Extract breakpoints from a string to make it a unique `StyleTemplate`
 * @param str Media Queries in inline style
 * @param transformer A function with parameters to create a `StyleTemplate`
 * @deprecated
 */
function withMediaInline(str, transformer) {
  const styleCollection = new StyleCollection();
  if (typeof str === 'string') {
    const values = parseMediaQueriesFromString(str);
    for (let index = 0; index < values.length; index++) {
      parseMediaQueryFromString(values[index]).forEach(_ => {
        styleCollection.add(transformer(_[0], _[1]));
      });
    }
  } else if (typeof str === 'number' || str === null || str === undefined) {
    styleCollection.add(transformer(str, null));
  } else {
    for (let index = 0; index < str.length; index++) {
      const val = str[index];
      if (typeof val === 'number' || val === null || val === undefined) {
        styleCollection.add(transformer(val, null));
      } else if (typeof val === 'string') {
        parseMediaQueryFromString(val).forEach(_ => {
          styleCollection.add(transformer(_[0], _[1]));
        });
      }
    }
  }
  return styleCollection.css;
}
/**
 * Extract media query from a string
 */
const parseMediaQueryFromString = memoize(key => {
  const valItem = key.split(/\@/g);
  const strValue = valItem.shift();
  const len = valItem.length;
  const value = isNaN(+strValue) ? strValue : +strValue;
  const re = [];
  if (len) {
    for (let j = 0; j < len; j++) {
      re.push([value, valItem[j]]);
    }
  } else {
    re.push([value, null]);
  }
  return re;
});
/**
 * Extract media queries from a string
 */
const parseMediaQueriesFromString = memoize(key => {
  return key.split(' ');
});
/**
 * @depracated use `withMediaInline` instead.
 */
function eachMedia(str, fn, withStyleCollection) {
  let styleCollection;
  if (withStyleCollection) {
    styleCollection = new StyleCollection();
  }
  if (typeof str === 'string') {
    const values = str.split(/\ /g);
    for (let index = 0; index < values.length; index++) {
      const valItem = values[index].split(/\@/g);
      const strValue = valItem.shift();
      const len = valItem.length;
      const value = isNaN(+strValue) ? strValue : +strValue;
      if (len) {
        for (let j = 0; j < len; j++) {
          resolveMediaEachItemStyle(fn, value, valItem[j], index, styleCollection);
        }
      } else {
        resolveMediaEachItemStyle(fn, value, null, index, styleCollection);
      }
    }
  } else if (typeof str === 'number' || typeof str === 'string' || str === null || str === undefined) {
    resolveMediaEachItemStyle(fn, str, null, 0, styleCollection);
  } else {
    // is array
    for (let index = 0; index < str.length; index++) {
      const val = str[index];
      if (typeof val === 'number' || typeof val === 'string') {
        resolveMediaEachItemStyle(fn, val, null, index, styleCollection);
      } else {
        const medias = val[1].split(/\@/g).filter(media => media);
        const strValue = val[0];
        const len = medias.length;
        if (len) {
          for (let ii = 0; ii < len; ii++) {
            resolveMediaEachItemStyle(fn, strValue, medias[ii], index, styleCollection);
          }
        } else {
          resolveMediaEachItemStyle(fn, strValue, null, index, styleCollection);
        }
      }
    }
  }
  if (styleCollection) {
    return styleCollection.css;
  }
}
function resolveMediaEachItemStyle(fn, val, media, index, styleCollection) {
  const styl = fn(val, media, index);
  if (styleCollection && styl) {
    styleCollection.add(styl);
  }
}
/**
 * Simple object check.
 * @param item
 */
function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }
  return mergeDeep(target, ...sources);
}
/**
 * Simple object check.
 * @param item
 */
function isObjectForTheme(item) {
  return item && typeof item === 'object' && !Array.isArray(item) && !(item instanceof StyleCollection) && !(item instanceof Color);
}
function mergeThemes(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (isObjectForTheme(target) && isObjectForTheme(source)) {
    for (const key in source) {
      if (isObjectForTheme(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          });
        }
        mergeThemes(target[key], source[key]);
      } else {
        const targetKey = target[key];
        const sourceKey = source[key];
        // Merge styles
        if (targetKey instanceof StyleCollection && typeof sourceKey === 'function') {
          target[key] = target[key].add(sourceKey);
        } else if (sourceKey instanceof Color) {
          target[key] = sourceKey;
        } else {
          // Object.assign(target, { [key]: source[key] });
          target[key] = source[key];
        }
      }
    }
  }
  return mergeThemes(target, ...sources);
}
class CoreTheme {
  constructor(rendererFactory, _document, platform, _classNamePrefix, _enableSelectorsFn) {
    this.rendererFactory = rendererFactory;
    this._classNamePrefix = _classNamePrefix;
    this._enableSelectorsFn = _enableSelectorsFn;
    this.themes = new Set();
    this._themeMap = new Map();
    this._styleMap = new Map();
    if (_enableSelectorsFn == null) {
      this._enableSelectorsFn = true;
    }
    this._document = _document;
    if (!isDevMode() && _classNamePrefix) {
      CoreTheme.classNamePrefix = this.classNamePrefix = _classNamePrefix;
    }
    if (platform.isBrowser) {
      // Clean
      const nodes = this._document.body.querySelectorAll('ly-s-c');
      if (nodes.length) {
        for (let index = 0; index < nodes.length; index++) {
          const element = nodes.item(index);
          this._document.body.removeChild(element);
        }
      }
    }
    this.firstElement = this._document.body.firstChild;
    this.renderer = this.rendererFactory.createRenderer(null, {
      id: 'ly',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }
  initializeTheme(themeConfig, globalVariables) {
    const allThemes = Array.isArray(themeConfig) ? themeConfig : [themeConfig];
    const themes = new Map();
    allThemes.forEach(item => {
      // Do not install themes that are already initialized.
      if (this.hasTheme(item.name)) {
        // throw new Error(`Theme '${item.name}' is already initialized.`);
        // }
      }
      if (themes.has(item.name)) {
        themes.get(item.name).push(item);
      } else {
        themes.set(item.name, [item]);
      }
    });
    themes.forEach(items => {
      if (globalVariables) {
        items.push(globalVariables);
      }
      if (items.length > 1) {
        mergeThemes(items[0], ...items.slice(1));
      }
      this._add(items[0]);
      this.themes.add(items[0].name);
    });
  }
  /**
   * add new theme
   * @param theme: ThemeVariables
   */
  _add(theme) {
    this._themeMap.set(theme.name, theme);
    this._styleMap.set(theme.name, new Map());
  }
  hasTheme(theme) {
    const name = typeof theme === 'string' ? theme : theme.name;
    return this._themeMap.has(name);
  }
  get(name) {
    return this._themeMap.get(name);
  }
  updateClassName(element, renderer, newClassname, oldClassname) {
    if (oldClassname) {
      renderer.removeClass(element, oldClassname);
    }
    renderer.addClass(element, newClassname);
  }
  static {
    this.ɵfac = function CoreTheme_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CoreTheme)(i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(LY_CLASS_NAME_PREFIX, 8), i0.ɵɵinject(LY_ENABLE_SELECTORS_FN, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CoreTheme,
      factory: CoreTheme.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreTheme, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.RendererFactory2
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i1.Platform
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LY_CLASS_NAME_PREFIX]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LY_ENABLE_SELECTORS_FN]
    }]
  }], null);
})();
var YPosition;
(function (YPosition) {
  YPosition["above"] = "above";
  YPosition["below"] = "below";
})(YPosition || (YPosition = {}));
var XPosition;
(function (XPosition) {
  XPosition["before"] = "before";
  XPosition["after"] = "after";
  XPosition["left"] = "left";
  XPosition["right"] = "right";
})(XPosition || (XPosition = {}));
const INITIAL_V = 'initial';
/**
 * @deprecated Use `OverlayPosition` instead.
 */
class Positioning {
  get offsetX() {
    return typeof this._offset === 'number' ? this._offset : this._offset.x || 0;
  }
  get offsetY() {
    return typeof this._offset === 'number' ? this._offset : this._offset.y || 0;
  }
  constructor(placement, xPosition, yPosition, origin, overlayElement, _themeVariables, _offset = 0, _flip = true) {
    this.placement = placement;
    this.xPosition = xPosition;
    this.yPosition = yPosition;
    this.origin = origin;
    this.overlayElement = overlayElement;
    this._themeVariables = _themeVariables;
    this._offset = _offset;
    this._offsetCheck = 16;
    this._originRect = this.origin.getBoundingClientRect();
    this._overlayElementRect = this.overlayElement.getBoundingClientRect();
    this.width = INITIAL_V;
    this.height = INITIAL_V;
    const offsetCheckx2 = this._offsetCheck * 2;
    this.createPosition();
    if (_flip) {
      for (let index = 0; index < 2; index++) {
        if (this.checkAll(false, true)) {
          this.createPosition();
        }
      }
    }
    // when there is not enough space
    if (this.checkAll(true, false)) {
      let requireUpdateOrigin = false;
      const _max_width = this._overlayElementRect.width + offsetCheckx2 > window.innerWidth;
      const _max_height = this._overlayElementRect.height + offsetCheckx2 > window.innerHeight;
      if (_max_height) {
        this.y = this._offsetCheck;
        this.height = `${window.innerHeight - offsetCheckx2}px`;
        requireUpdateOrigin = true;
      } else if (this.checkBottom(false, false)) {
        this.y += this.checkBottom(true, false);
        requireUpdateOrigin = true;
      } else if (this.checkTop(false, false)) {
        this.y -= this.checkTop(true, false);
        requireUpdateOrigin = true;
      }
      if (_max_width) {
        this.x = this._offsetCheck;
        this.width = `${window.innerWidth - offsetCheckx2}px`;
        requireUpdateOrigin = true;
      } else if (this.checkRight(false, false)) {
        this.x += this.checkRight(true, false);
        requireUpdateOrigin = true;
      } else if (this.checkLeft(false, false)) {
        this.x -= this.checkLeft(true, false);
        requireUpdateOrigin = true;
      }
      if (requireUpdateOrigin) {
        this.updateOrigin();
      }
    }
    if (this._offset) {
      this.updateOrigin();
    }
    // round result
    this.x = Math.round(this.x);
    this.y = Math.round(this.y);
    this.ax = Math.round(this.ax);
    this.ay = Math.round(this.ay);
  }
  createPosition() {
    if (this.xPosition && this.yPosition) {
      throw new Error(`You can not use \`xPosition\` and \`yPosition\` together, use only one of them.`);
    }
    // if ((this.xPosition || this.yPosition) && !this.placement) {
    //   throw new Error(`\`placement\` is required.`);
    // }
    let x = this._originRect.x,
      y = this._originRect.y,
      ox = 'center',
      oy = 'center';
    // if (this.placement) {
    if (this.placement === YPosition.above) {
      x += (this._originRect.width - this._overlayElementRect.width) / 2;
      y += -this._overlayElementRect.height;
      oy = 'bottom';
      // set offset
      y -= this.offsetY;
    } else if (this.placement === YPosition.below) {
      x += (this._originRect.width - this._overlayElementRect.width) / 2;
      y += this._originRect.height;
      oy = 'top';
      // set offset
      y += this.offsetY;
    } else {
      const dir = this._themeVariables.getDirection(this.placement);
      if (dir === DirPosition.left) {
        ox = '100%';
        x += -this._overlayElementRect.width;
        y += (this._originRect.height - this._overlayElementRect.height) / 2;
        // set offset
        x -= this.offsetX;
      } else if (dir === DirPosition.right) {
        ox = '0%';
        x += this._originRect.width;
        y += (this._originRect.height - this._overlayElementRect.height) / 2;
        // set offset
        x += this.offsetX;
      }
    }
    if (this.xPosition) {
      const dir = this._themeVariables.getDirection(this.xPosition);
      if (dir === DirPosition.right) {
        ox = '0%';
        x = this._originRect.x;
        // set offset
        x += this.offsetX;
      } else if (dir === DirPosition.left) {
        ox = '100%';
        x = this._originRect.x + this._originRect.width - this._overlayElementRect.width;
        // set offset
        x -= this.offsetX;
      }
    } else if (this.yPosition) {
      if (this.yPosition === YPosition.above) {
        y = this._originRect.y + this._originRect.height - this._overlayElementRect.height;
        oy = '100%';
        // set offset
        y += this.offsetY;
      } else if (this.yPosition === YPosition.below) {
        y = this._originRect.y;
        oy = '0%';
        // set offset
        y -= this.offsetY;
      }
    }
    // }
    this.x = x;
    this.y = y;
    this.ax = x;
    this.ay = y;
    this.ox = ox;
    this.oy = oy;
    return {
      x: Math.round(x),
      y: Math.round(y),
      ox,
      oy
    };
  }
  checkLeft(returnVal, invertIfNeed) {
    const rest = this.ax - this._offsetCheck;
    if (returnVal) {
      return rest;
    }
    if (rest < 0) {
      if (invertIfNeed) {
        if (this.placement !== YPosition.above && this.placement !== YPosition.below) {
          this.placement = invertPlacement(this.placement);
        }
        if (this.xPosition) {
          this.xPosition = invertPlacement(this.xPosition);
        }
      }
      return true;
    }
    return false;
  }
  checkRight(returnVal, invertIfNeed) {
    const rest = window.innerWidth - (this.ax + this._overlayElementRect.width + this._offsetCheck);
    if (returnVal) {
      return rest;
    }
    if (rest < 0) {
      if (invertIfNeed) {
        if (this.placement !== YPosition.above && this.placement !== YPosition.below) {
          this.placement = invertPlacement(this.placement);
        }
        if (this.xPosition) {
          this.xPosition = invertPlacement(this.xPosition);
        }
      }
      return true;
    }
    return false;
  }
  checkTop(returnVal, invertIfNeed) {
    const rest = this.ay - this._offsetCheck;
    if (returnVal) {
      return rest;
    }
    if (rest < 0) {
      if (invertIfNeed) {
        if (this.placement === YPosition.above || this.placement === YPosition.below) {
          this.placement = invertPlacement(this.placement);
        }
        if (this.yPosition) {
          this.yPosition = invertPlacement(this.yPosition);
        }
      }
      return true;
    }
    return false;
  }
  checkBottom(returnVal, invertIfNeed) {
    const rest = window.innerHeight - (this.ay + this._overlayElementRect.height + this._offsetCheck);
    if (returnVal) {
      return rest;
    }
    if (rest < 0) {
      if (invertIfNeed) {
        if (this.placement === YPosition.above || this.placement === YPosition.below) {
          this.placement = invertPlacement(this.placement);
        }
        if (this.yPosition) {
          this.yPosition = invertPlacement(this.yPosition);
        }
      }
      return true;
    }
    return false;
  }
  checkAll(returnVal, invertIfNeed) {
    return this.checkLeft(returnVal, invertIfNeed) || this.checkRight(returnVal, invertIfNeed) || this.checkTop(returnVal, invertIfNeed) || this.checkBottom(returnVal, invertIfNeed);
  }
  updateOrigin() {
    // do not update if it is defined
    if (this._origin) {
      return;
    }
    this._origin = true;
    const oax = this._originRect.x + this._originRect.width / 2;
    const oay = this._originRect.y + this._originRect.height / 2;
    const vax = this.x + this._overlayElementRect.width / 2;
    const vay = this.y + this._overlayElementRect.height / 2;
    this.ox = `${oax - vax + this._overlayElementRect.width / 2}px`;
    this.oy = `${oay - vay + this._overlayElementRect.height / 2}px`;
  }
}
function invertPlacement(placement) {
  if (placement === YPosition.above) {
    return YPosition.below;
  } else if (placement === YPosition.below) {
    return YPosition.above;
  } else if (placement === XPosition.after) {
    return XPosition.before;
  } else if (placement === XPosition.before) {
    return XPosition.after;
  } else if (placement === XPosition.right) {
    return XPosition.left;
  } else if (placement === XPosition.left) {
    return XPosition.right;
  }
  return placement;
}
const REF_REG_EXP = /\{([\w-]+)\}/g;
let nextKeyFrameId = 0;
const yClassID = new StringIdGenerator();
const keyframesUniqueId = new StringIdGenerator();
class StylesInDocument {
  constructor() {
    this.styles = {};
    this.styleContainers = new Map();
    this.styleElementGlobalMap = new Map();
  }
  static {
    this.ɵfac = function StylesInDocument_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StylesInDocument)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StylesInDocument,
      factory: StylesInDocument.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StylesInDocument, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const THEME_MAP = new Map();
class LyTheme2 {
  get directionChanged() {
    return this._directionChanged.asObservable();
  }
  /** Get Theme Variables */
  get variables() {
    return this.config;
  }
  constructor(stylesInDocument, core, themeName, themeConfig, globalVariables, _document, _ngZone, _platform) {
    this.stylesInDocument = stylesInDocument;
    this.core = core;
    this._ngZone = _ngZone;
    this._platform = _platform;
    this._elementsMap = new Map();
    /** Event emitted when the direction has changed. */
    this._directionChanged = new Subject();
    /** Event emitted when the theme has changed. */
    this._themeChanged = new Subject();
    this.themeChanged = this._themeChanged.asObservable();
    this.themeMap = THEME_MAP;
    /** ssr or hmr */
    this.isDevOrServer = isDevMode() || !this._platform.isBrowser;
    this._document = _document;
    if (themeConfig) {
      core.initializeTheme(themeConfig, globalVariables);
    }
    if (themeName) {
      this.setUpTheme(themeName);
    }
  }
  setUpTheme(themeName) {
    if (!this.config) {
      const theme = this.core.get(themeName);
      if (theme === undefined) {
        throw new Error(`Theme ${themeName} not found in CoreTheme`);
      }
      this.config = theme;
      this._styleMap = new Map();
      this.elements = themeName in this.stylesInDocument.styles ? this.stylesInDocument.styles[themeName] : this.stylesInDocument.styles[themeName] = new Map();
      if (!this.initialTheme) {
        this.initialTheme = this.config.name;
      }
      if (!this.themeMap.has(this.initialTheme)) {
        this.themeMap.set(this.initialTheme, {
          base: this.initialTheme,
          change: null
        });
      }
    }
  }
  /**
   * Build multiple styles and render them in the DOM
   */
  renderStyleSheet(styles) {
    return this._createStyleContent2(styles, null, null, TypeStyle.Multiple);
  }
  /**
   * Build the styles and render them in the DOM
   */
  renderStyle(styleOrId, priorityOrStyle, priority) {
    if (typeof styleOrId === 'string') {
      return this._createStyleContent2(priorityOrStyle, styleOrId, priority, TypeStyle.LylStyle);
    }
    return this._createStyleContent2(styleOrId, null, priorityOrStyle, TypeStyle.LylStyle);
  }
  /**
   * Add a new dynamic style, use only within @Input()
   * @param id Unique id
   * @param style Styles
   * @param el Element
   * @param instance The instance of this, this replaces the existing style with a new one when it changes
   * @param parentStyle Parent Style
   */
  addStyle(id, style, el, instance, priority, parentStyle) {
    const newClass = this._createStyleContent2(style, id, priority, TypeStyle.OnlyOne, false, parentStyle);
    if (newClass === instance) {
      return newClass;
    }
    if (el) {
      if (instance) {
        el.classList.remove(instance);
      }
      el.classList.add(newClass);
    }
    return newClass;
  }
  /**
   * Create basic style
   * @param style Styles.
   * Note: Use only with immutable variable.
   * @param priority Priority of style
   * @param parentStyle
   */
  style(style, priority, parentStyle) {
    return this._createStyleContent2(style, null, priority, TypeStyle.OnlyOne, false, parentStyle);
  }
  updateClassName(element, renderer, newClassname, oldClassname) {
    this.core.updateClassName(element, renderer, newClassname, oldClassname);
  }
  updateClass(element, renderer, newClass, oldClass) {
    if (newClass === oldClass) {
      return newClass;
    }
    this.updateClassName(element, renderer, newClass, oldClass);
    return newClass;
  }
  /**
   * Change the current theme for another.
   * @param themeName theme name
   */
  setTheme(themeName) {
    if (!this._platform.isBrowser) {
      throw new Error(`\`theme.setTheme('theme-name')\` is only available in browser platform`);
    }
    if (themeName !== this.config.name) {
      const theme = this.themeMap.get(this.initialTheme);
      const dir = this.config.direction;
      if (theme == null) {
        throw new Error(`Theme ${themeName} not found in themeMap`);
      }
      theme.change = themeName;
      this.config = this.core.get(themeName);
      this.config.direction = dir;
      this._updateAllStyles();
      this._themeChanged.next();
    }
  }
  /** Toggle right-to-left/left-to-right */
  toggleDirection() {
    const current = this.config.direction;
    this.config.direction = current === Dir.ltr ? Dir.rtl : Dir.ltr;
    this._updateAllStyles();
    this._directionChanged.next();
  }
  setDirection(dir) {
    if (this.config.direction !== dir) {
      this.config.direction = dir;
      this._updateAllStyles();
      this._directionChanged.next();
    }
  }
  _updateAllStyles() {
    this.elements.forEach((_, key) => {
      const styleData = _STYLE_MAP.get(key);
      if (styleData.requireUpdate) {
        this._createStyleContent2(styleData.styles, styleData.id, styleData.priority, styleData.type, true, styleData.parentStyle);
      }
    });
  }
  /**
   * Create a simple style
   * return className
   * @param id id of style
   * @param css style object or string
   * @param priority style priority(default: 0)
   */
  addSimpleStyle(id, css, priority, parentStyle) {
    return this._createStyleContent2(css, id, priority, TypeStyle.OnlyOne, false, parentStyle);
  }
  /**
   * Add new add a new style sheet
   * @param styles styles
   * @param priority priority for style
   */
  addStyleSheet(styles, priority) {
    return this._createStyleContent2(styles, null, priority, TypeStyle.Multiple);
  }
  /**
   * Check if a style exist
   * @param stylesOrId Style or Id of a style
   */
  existStyle(stylesOrId) {
    if (_STYLE_MAP.has(stylesOrId)) {
      const styleMap = _STYLE_MAP.get(stylesOrId);
      return !!(styleMap.classes || styleMap[this.initialTheme]);
    }
    return false;
  }
  /**
   * return selectors if exists
   *
   * e.g.
   *
   * ```ts
   * {
   *   root: '.c'
   * }
   * ```
   * @param styles id
   */
  selectorsOf(styles) {
    const themeName = this.initialTheme;
    if (!_STYLE_MAP.has(styles)) {
      _STYLE_MAP.set(styles, {
        isNewStyle: true,
        styles: styles,
        type: TypeStyle.Multiple,
        css: {},
        id: null
      });
    }
    const styleMap = _STYLE_MAP.get(styles);
    const themeNameForSelectors = getThemeNameForSelectors(themeName);
    const classesMap = styleMap[themeNameForSelectors] || (styleMap[themeNameForSelectors] = {});
    return classesMap;
  }
  // private _selectors(currentStyles: any) {
  //   return (externalStyle: any) => {
  //     if (currentStyles !== externalStyle) {
  //       this.renderStyleSheet(externalStyle);
  //     }
  //     return this.selectorsOf(externalStyle);
  //   };
  // }
  selectorOf(styles) {
    const themeName = this.initialTheme;
    const styleMap = _STYLE_MAP.get(styles);
    return styleMap.classes || styleMap[themeName];
  }
  /**
   * For internal use only
   * @docs-private
   */
  _createStyleContent2(styles, id, priority, type, forChangeTheme, parentStyle) {
    const newId = id || styles;
    if (!_STYLE_MAP.has(newId)) {
      _STYLE_MAP.set(newId, {
        isNewStyle: true,
        priority,
        styles: styles,
        type,
        css: {},
        id,
        parentStyle
      });
    }
    const styleMap = _STYLE_MAP.get(newId);
    const themeName = this.initialTheme;
    const isCreated = styleMap.isNewStyle || !(styleMap.classes || styleMap[themeName]);
    if (isCreated || forChangeTheme) {
      styleMap.isNewStyle = false;
      // create new style for new theme
      let css;
      const themeMap = this.themeMap.get(this.initialTheme);
      const config = this.core.get(themeMap.change || themeName);
      if (typeof styles === 'function') {
        styleMap.requireUpdate = true;
        css = type === TypeStyle.LylStyle ? this.core._enableSelectorsFn ? createLylStyle(styleMap, styles(config, _selectors(styles, this), null), themeName, this.core.classNamePrefix) : createLylStyle(styleMap, styles(config, this, null), themeName, this.core.classNamePrefix) : this.core._enableSelectorsFn ? groupStyleToString(styleMap, styles(config, _selectors(styles, this), null), themeName, id, type, config, this.core.classNamePrefix) : groupStyleToString(styleMap, styles(config, this), themeName, id, type, config, this.core.classNamePrefix);
        if (!forChangeTheme) {
          styleMap.css[themeName] = css;
        }
      } else {
        /** create a new id for style that does not <-<require>-> changes */
        css = groupStyleToString(styleMap, styles, themeName, newId, type, config, this.core.classNamePrefix);
        styleMap.css = css;
      }
      if (!this.elements.has(newId)) {
        const newEl = this._createStyleElement();
        if (styleMap.requireUpdate) {
          // This is required for when a theme changes
          this.elements.set(newId, newEl);
        } else if (this.isDevOrServer) {
          // in dev mode or server it is not necessary
          // since the styles will not change
          this.stylesInDocument.styleElementGlobalMap.set(newId, newEl);
        }
        this._renderCss(newEl, css, styleMap.priority);
      }
      if (forChangeTheme) {
        const el = this.elements.get(newId);
        el.removeChild(el.firstChild);
        el.appendChild(this._document.createTextNode(css));
      }
    } else if (this.isDevOrServer) {
      /**
       * append child style if not exist in dom
       * for ssr or hmr
       */
      if (!this.elements.has(newId)) {
        const _css = styleMap.css[themeName] || styleMap.css;
        const map = this.stylesInDocument.styleElementGlobalMap;
        if (styleMap.requireUpdate) {
          const styleElement = this._createStyleElement();
          this.elements.set(newId, styleElement);
          this._renderCss(styleElement, _css, styleMap.priority);
        } else if (!map.has(newId)) {
          const styleElement = this._createStyleElement();
          map.set(newId, styleElement);
          this._renderCss(styleElement, _css, styleMap.priority);
        }
      }
    }
    return styleMap.classes || styleMap[themeName];
  }
  _createStyleContainer(priority) {
    priority = priority ?? 0;
    const {
      styleContainers
    } = this.stylesInDocument;
    if (!styleContainers.has(priority)) {
      const el = this.core.renderer.createElement(`ly-s-c`);
      if (isDevMode()) {
        this.core.renderer.setAttribute(el, 'priority', `${priority}`);
      }
      if (styleContainers.size === 0) {
        styleContainers.set(priority, el);
        this._document.body.insertBefore(el, this._document.body.firstChild);
        return el;
      } else {
        styleContainers.set(priority, el);
        const refChild = this._getRefFromAPriority(priority);
        this._document.body.insertBefore(el, refChild);
        return styleContainers.get(priority);
      }
    }
    return styleContainers.get(priority);
  }
  _getRefFromAPriority(priority) {
    const {
      styleContainers
    } = this.stylesInDocument;
    const keys = Array.from(styleContainers.keys()).sort(sortNumberArray);
    const key = keys.find(_ => priority < _);
    return key !== undefined && styleContainers.get(key) || this.core.firstElement;
  }
  _createStyleElement() {
    const styleElement = this._document.createElement('style');
    return styleElement;
  }
  _renderCss(styleElement, css, priority) {
    const container = this._createStyleContainer(priority);
    styleElement.appendChild(this._document.createTextNode(css));
    container.appendChild(styleElement);
  }
  requestAnimationFrame(fn) {
    if (typeof requestAnimationFrame === 'function') {
      this._ngZone.runOutsideAngular(() => {
        requestAnimationFrame(() => {
          fn();
        });
      });
    } else {
      fn();
    }
  }
  static {
    this.ɵfac = function LyTheme2_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyTheme2)(i0.ɵɵinject(StylesInDocument), i0.ɵɵinject(CoreTheme), i0.ɵɵinject(LY_THEME_NAME), i0.ɵɵinject(LY_THEME, 8), i0.ɵɵinject(LY_THEME_GLOBAL_VARIABLES, 8), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.Platform));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyTheme2,
      factory: LyTheme2.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyTheme2, [{
    type: Injectable
  }], () => [{
    type: StylesInDocument
  }, {
    type: CoreTheme
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LY_THEME_NAME]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LY_THEME]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LY_THEME_GLOBAL_VARIABLES]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: i1.Platform
  }], null);
})();
function createLylStyle(styleMap, styles, themeName, classNamePrefix) {
  // use current class or set new
  let className;
  className = styleMap[themeName] || (styleMap[themeName] = isDevMode() ? styleMap.id ? `${toValidClassName(styleMap.id)}-${createNextClassId(classNamePrefix)}` : `${styleMap.styles.name || 'ii'}-${createNextClassId(classNamePrefix)}` : createNextClassId(classNamePrefix));
  return styles(`.${className}`);
}
function groupStyleToString(styleMap, styles, themeName, id, typeStyle, themeVariables, classNamePrefix) {
  // for styles type string
  if (typeStyle === TypeStyle.OnlyOne) {
    // use current class or set new
    const className = styleMap.requireUpdate ? styleMap[themeName] || (styleMap[themeName] = createNextClassId(classNamePrefix)) : styleMap.classes ? styleMap.classes : styleMap.classes = createNextClassId(classNamePrefix);
    let rules;
    if (typeof styles === 'string') {
      rules = `.${className}{${styles}}`;
    } else {
      rules = styleToString(id, null, styles, themeVariables, className);
    }
    if (styleMap.parentStyle) {
      const styleMapOfParentStyle = _STYLE_MAP.get(styleMap.parentStyle);
      if (!styleMapOfParentStyle) {
        throw new Error(`The parentStyle not exist or is called before being created.`);
      }
      return replaceRefs(rules, styleMapOfParentStyle[themeName]);
    }
    return rules;
  }
  // for multiples styles
  const themeNameForSelectors = getThemeNameForSelectors(themeName);
  const classesMap = styleMap[themeName] || (styleMap[themeName] = {});
  const selectorsMap = styleMap[themeNameForSelectors] || (styleMap[themeNameForSelectors] = {});
  const styleGroup = styles;
  let content = '';
  const name = styleGroup.$name ? `${styleGroup.$name}-` : '';
  // set priority
  if (styleGroup.$priority != null) {
    styleMap.priority = styleGroup.$priority;
  }
  if (!styleMap.keys) {
    styleMap.keys = Object.keys(styles);
  }
  const keys = styleMap.keys;
  /** This loop creates the classes if necessary */
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const value = styles[key];
    if (key === '$global' || key === '$keyframes') {
      continue;
    }
    if (typeof value === 'function' || Array.isArray(value)) {
      // lyl
      // set new id if not exist
      if (!(key in classesMap)) {
        classesMap[key] = isDevMode() ? key === 'root' ? `${toValidClassName(name)}${createNextClassId(classNamePrefix)}` : `${toValidClassName(name + key)}-${createNextClassId(classNamePrefix)}` : createNextClassId(classNamePrefix);
      }
    } else if (typeof value === 'object' || value === null) {
      // TODO: @deprecated
      // remove this in the future
      // set new id if not exist
      if (!(key in classesMap)) {
        classesMap[key] = isDevMode() ? toValidClassName(`y-${name}${key}-${createNextClassId(classNamePrefix)}`) : createNextClassId(classNamePrefix);
      }
    } else {
      continue;
    }
    if (!(key in selectorsMap)) {
      selectorsMap[key] = `.${classesMap[key]}`;
    }
  }
  let requireReplaceRefs = false;
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const value = styles[key];
    const isArray = Array.isArray(value);
    if (typeof value === 'function' || isArray) {
      // lyl
      if (key === '$global') {
        content += arrayLylToString(value, ``);
      } else {
        const selector = selectorsMap[key];
        content += arrayLylToString(value, selector);
      }
    } else if (key === '$keyframes') {
      console.warn(`'$keyframes' is deprecated, use '$global' instead to create keyframes.`);
      requireReplaceRefs = true;
      content += keyframesToString(name, classesMap, value, themeVariables);
    } else if (typeof value === 'object' && value !== null) {
      requireReplaceRefs = true;
      const currentClassName = classesMap[key];
      const style = styleToString(key, styleGroup.$name, value, themeVariables, currentClassName);
      content += style;
      if (value === null) {
        console.warn(`__`, {
          style
        });
      }
    } // ignore if value === null
  }
  if (requireReplaceRefs) {
    return replaceRefs(content, classesMap);
  }
  return content;
}
function arrayLylToString(value, selector) {
  let content = '';
  if (Array.isArray(value)) {
    for (let index = 0; index < value.length; index++) {
      const item = value[index];
      if (item) {
        content += lylToString(item, selector);
      }
    }
  } else {
    return lylToString(value, selector);
  }
  return content;
}
function lylToString(value, selector) {
  let content = '';
  if (!value) return content;
  if (value.length) {
    // if lyl is a function
    content += value(selector);
  } else {
    const st = value();
    if (!st) return content;
    if (Array.isArray(st)) {
      content += st.reduce((acc, curr) => acc + (curr?.(selector) ?? ''), '');
    } else {
      content += st(selector);
    }
  }
  return content;
}
function replaceRefs(str, data) {
  return str.replace(REF_REG_EXP, (_match, token) => {
    const className = data[token];
    if (className) {
      return `.${data[token]}`;
    } else {
      return data[`@г.->-${token}`];
    }
  });
}
/**
 * {color:'red'} to .className{color: red}
 */
function styleToString(key, $name, ob, themeVariables, currentKey, parentKey) {
  let content = '';
  let subContent = '';
  let keyAndValue = '';
  let newKey;
  if (parentKey) {
    if (currentKey.indexOf('&') !== -1) {
      newKey = currentKey.replace(/&/g, parentKey);
    } else if (currentKey.indexOf('@media') === 0) {
      newKey = `${currentKey}`;
    } else if (currentKey === '@global' || parentKey === '@global') {
      newKey = currentKey;
    } else {
      newKey = `${parentKey} ${currentKey}`;
    }
  } else if (key === '@global') {
    newKey = key;
  } else {
    newKey = `.${currentKey}`;
  }
  for (const styleKey in ob) {
    if (ob.hasOwnProperty(styleKey)) {
      const element = ob[styleKey];
      // Omit style with value null
      if (element != null) {
        // Check if is Object literal
        if (element.constructor === Object) {
          subContent += styleToString(key, $name, element, themeVariables, styleKey, newKey);
        } else {
          keyAndValue += convertToStyleValue(styleKey, element, themeVariables);
        }
      }
    }
  }
  if (keyAndValue) {
    if (isDevMode()) {
      let lin = '\n\n';
      if ($name) {
        lin += `/** Style Sheet name: ${$name} */\n`;
      }
      lin += `/** Style Key: ${key} */\n`;
      content += `${lin}`;
    }
    if (newKey.indexOf('@media') === 0) {
      content += `${newKey}`;
      keyAndValue = `${parentKey}{${keyAndValue}}`;
    } else if (parentKey && parentKey === '@global') {
      content += `${currentKey}`;
    } else {
      content += `${newKey}`;
    }
    content += `{${keyAndValue}}`;
  }
  return content + subContent;
}
function convertToStyleValue(key, value, themeVariables) {
  const newStyleKey = converterToCssKeyAndStyleCache(key, themeVariables);
  if (value.constructor === Array) {
    let lin = '';
    for (let index = 0; index < value.length; index++) {
      lin += `${newStyleKey}:${value[index]};`;
    }
    return lin;
  } else {
    return `${newStyleKey}:${value};`;
  }
}
function keyframesToString(styleName, keysMap, keyframes, themeVariables) {
  let content = '';
  for (const name in keyframes) {
    if (keyframes.hasOwnProperty(name)) {
      const keyframe = keyframes[name];
      // Sometimes the name of a class can be the same as the name of a keyframe,
      // so we add a character to be different
      const newUniqueName = `@г.->-${name}`;
      // set new id if not exist
      const newName = newUniqueName in keysMap ? keysMap[newUniqueName] : keysMap[newUniqueName] = isDevMode() ? toValidClassName(`${styleName}${name}-${createNextKeyframeId()}-v`) : createNextKeyframeId();
      content += `@keyframes ${newName}{`;
      for (const percent in keyframe) {
        if (keyframe.hasOwnProperty(percent)) {
          content += `${percent}%{`;
          const styles = keyframe[percent];
          for (const key in styles) {
            if (styles.hasOwnProperty(key)) {
              const val = styles[key];
              content += convertToStyleValue(key, val, themeVariables);
            }
          }
          content += `}`;
        }
      }
      content += `}`;
    }
  }
  return content;
}
function converterToCssKeyAndStyle(str, themeVariables) {
  const hyphenCase = toHyphenCase(str);
  if (hyphenCase.indexOf(DirAlias.before) !== -1) {
    return dirCache(str, hyphenCase, themeVariables, DirAlias.before);
  } else if (hyphenCase.indexOf(DirAlias.after) !== -1) {
    return dirCache(str, hyphenCase, themeVariables, DirAlias.after);
  } else if (hyphenCase.indexOf(YPosition.above) !== -1) {
    return YPositionCache(str, hyphenCase, themeVariables, YPosition.above, TOP);
  } else if (hyphenCase.indexOf(YPosition.below) !== -1) {
    return YPositionCache(str, hyphenCase, themeVariables, YPosition.below, BOTTOM);
  }
  return hyphenCase;
}
function toValidClassName(str) {
  const s = str.replace(/^[0-9]|[^\w\-]/g, _ => {
    return `_${_.charCodeAt(0)}`;
  });
  return s;
}
function toHyphenCase(str) {
  return str.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);
}
function converterToCssKeyAndStyleCache(str, themeVariables) {
  const map = STYLE_KEYS_MAP[themeVariables.direction];
  return str in map ? map[str] : map[str] = converterToCssKeyAndStyle(str, themeVariables);
}
const ignoreCSSKEY = {
  'break-after': 'break-after',
  'break-before': 'break-before',
  'page-break-after': 'page-break-after',
  'page-break-before': 'page-break-before'
};
const STYLE_KEYS_MAP = {
  rtl: {
    ...ignoreCSSKEY
  },
  ltr: {
    ...ignoreCSSKEY
  }
};
const BOTTOM = 'bottom';
const TOP = 'top';
function dirCache(original, val, themeVariables, dirAlias) {
  const map = STYLE_KEYS_MAP[themeVariables.direction];
  // Replace in original, for do not repeat this again
  return map[original] = val.replace(dirAlias, themeVariables.getDirection(dirAlias));
}
function YPositionCache(original, val, themeVariables, pos, to) {
  const map = STYLE_KEYS_MAP[themeVariables.direction];
  // Replace in original, for do not repeat this again
  return map[original] = val.replace(pos, to);
}
function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}
function createNextClassId(classNamePrefix) {
  return classNamePrefix ? `${classNamePrefix}${yClassID.next()}` : yClassID.next();
}
/**
 * Create next class id from key
 * @private
 */
const ck = memoize(_key => {
  return createNextClassId(CoreTheme.classNamePrefix);
});
/**
 * Create next selector id from key
 */
const dot = memoize(key => {
  return `.${ck(key)}`;
});
function createNextKeyframeId() {
  return `k${(nextKeyFrameId++).toString(36)}`;
}
function sortNumberArray(a, b) {
  return a - b;
}
function _selectors(currentStyles, theme) {
  const fn = externalStyle => {
    if (currentStyles !== externalStyle) {
      theme.renderStyleSheet(externalStyle);
    }
    return theme.selectorsOf(externalStyle);
  };
  if (theme.core._enableSelectorsFn) {
    fn.selectorsOf = theme.selectorsOf.bind(theme);
    fn.renderStyleSheet = theme.renderStyleSheet.bind(theme);
  }
  return fn;
}
const LY_COMMON_STYLES = {
  fill: _className => `${_className}{position:absolute;top:0;bottom:0;left:0;right:0;}`,
  visuallyHidden: _className => `${_className}{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;outline:0;-webkit-appearance:none;-moz-appearance:none;}`,
  button: _className => `${_className}{-webkit-tap-highlight-color:transparent;background-color:transparent;border:0;-moz-appearance:none;-webkit-appearance:none;margin:0;outline:none;box-sizing:border-box;position:relative;text-decoration-line:none;-webkit-text-decoration-line:none;}${_className}::-moz-focus-inner{border:0;}`
};
const LY_COMMON_STYLES_DEPRECATED = {
  fill: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
    outline: 0,
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none'
  },
  button: {
    '-webkit-tap-highlight-color': 'transparent',
    backgroundColor: `transparent`,
    border: 0,
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    margin: 0,
    outline: 'none',
    boxSizing: 'border-box',
    position: 'relative',
    textDecorationLine: 'none',
    '-webkit-text-decoration-line': 'none',
    '&::-moz-focus-inner': {
      border: 0
    }
  }
};
class LyCoreStyles {
  constructor(theme) {
    this.theme = theme;
    this.classes = this.theme.addStyleSheet(LY_COMMON_STYLES_DEPRECATED);
  }
  static {
    this.ɵfac = function LyCoreStyles_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyCoreStyles)(i0.ɵɵinject(LyTheme2));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyCoreStyles,
      factory: LyCoreStyles.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyCoreStyles, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: LyTheme2
  }], null);
})();
const styles$1 = theme => ({
  rippleContainer: {
    position: 'absolute',
    width: '2px',
    height: '2px',
    background: 'currentColor',
    opacity: '.2',
    borderRadius: '50%',
    transform: 'scale(0)',
    transition: `opacity ${theme.ripple.transition.opacity},transform ${theme.ripple.transition.transform}`,
    pointerEvents: 'none'
  },
  container: {
    ...LY_COMMON_STYLES_DEPRECATED.fill,
    overflow: 'hidden',
    pointerEvents: 'none',
    borderRadius: 'inherit'
  }
});
class LyRippleService {
  constructor(theme) {
    this.theme = theme;
    this.classes = this.theme.addStyleSheet(styles$1);
  }
  static {
    this.ɵfac = function LyRippleService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyRippleService)(i0.ɵɵinject(LyTheme2));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyRippleService,
      factory: LyRippleService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyRippleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: LyTheme2
  }], null);
})();
function mixinDisableRipple(base) {
  return class extends base {
    get disableRipple() {
      return this._disableRipple;
    }
    set disableRipple(val) {
      if (this._platform.isBrowser && val !== this._disableRipple) {
        const newVal = this._disableRipple = coerceBooleanProperty(val);
        // remove previous ripple if exist
        this._removeRippleEvents();
        if (!newVal) {
          // add ripple
          Promise.resolve(null).then(() => {
            const triggerElement = this._triggerElement.nativeElement;
            const rippleContainer = this._rippleContainer && this._rippleContainer.nativeElement || triggerElement;
            this._ripple = new Ripple(this._theme.variables, this._ngZone, this._theme.addStyleSheet(styles$1), rippleContainer, this._platform, triggerElement);
            this._ripple.setConfig(this._rippleConfig);
          });
        }
      }
    }
    constructor(...args) {
      super(...args);
      this._rippleConfig = {};
    }
    _removeRippleEvents() {
      if (this._platform.isBrowser) {
        if (this._ripple) {
          this._ripple.removeEvents();
          this._ripple = null;
        }
      }
    }
  };
}
function mixinDisabled(base) {
  return class extends base {
    get disabled() {
      return this._superHyperInternalPropertyDisabled;
    }
    set disabled(value) {
      this._superHyperInternalPropertyDisabled = coerceBooleanProperty(value);
    }
    constructor(...args) {
      super(...args);
      this._superHyperInternalPropertyDisabled = false;
    }
  };
}
function mixinColor(base) {
  return class extends base {
    get color() {
      return this._superHyperInternalPropertyColor;
    }
    set color(val) {
      const defaultColor = val;
      if (defaultColor !== this.color) {
        this._superHyperInternalPropertyColor = defaultColor;
      }
    }
    constructor(...args) {
      super(...args);
    }
  };
}
function mixinBg(base) {
  return class extends base {
    get bg() {
      return this._superHyperInternalPropertyBg;
    }
    set bg(val) {
      const defaultColor = val;
      if (defaultColor !== this.bg) {
        this._superHyperInternalPropertyBg = defaultColor;
      }
    }
    constructor(...args) {
      super(...args);
    }
  };
}
function mixinRaised(base) {
  return class extends base {
    get raised() {
      return this._superHyperInternalPropertyRaised;
    }
    set raised(value) {
      this._superHyperInternalPropertyRaised = coerceBooleanProperty(value);
    }
    constructor(...args) {
      super(...args);
    }
  };
}
function mixinOutlined(base) {
  return class extends base {
    get outlined() {
      return this._superHyperInternalPropertyOutlined;
    }
    set outlined(value) {
      this._superHyperInternalPropertyOutlined = coerceBooleanProperty(value);
    }
    constructor(...args) {
      super(...args);
    }
  };
}
function mixinElevation(base) {
  return class extends base {
    get elevation() {
      return this._superHyperInternalPropertyElevation;
    }
    set elevation(value) {
      this._superHyperInternalPropertyElevation = value;
    }
    constructor(...args) {
      super(...args);
    }
  };
}
function mixinShadowColor(base) {
  return class extends base {
    get shadowColor() {
      return this._superHyperInternalPropertyShadowColor;
    }
    set shadowColor(value) {
      this._superHyperInternalPropertyShadowColor = value;
    }
    constructor(...args) {
      super(...args);
    }
  };
}
const DEFAULT_TAB_INDEX = 0;
function mixinTabIndex(base) {
  return class extends base {
    get tabIndex() {
      return this.disabled ? -1 : this._tabIndex;
    }
    set tabIndex(value) {
      this._tabIndex = value != null ? value : DEFAULT_TAB_INDEX;
    }
    constructor(...args) {
      super(...args);
      this._tabIndex = DEFAULT_TAB_INDEX;
    }
  };
}
const DEFAULT_BG = 'paper';
class LyPaperBase {
  constructor(_theme, _ngZone, _platform) {
    this._theme = _theme;
    this._ngZone = _ngZone;
    this._platform = _platform;
  }
}
const LyPaperMixinBase = mixinStyleUpdater(mixinBg(mixinColor(mixinRaised(mixinOutlined(mixinElevation(mixinShadowColor(mixinDisableRipple(LyPaperBase))))))));
class LyPaper extends LyPaperMixinBase {
  set hasText(val) {
    this._hasText = coerceBooleanProperty(val);
  }
  get hasText() {
    return this._hasText;
  }
  constructor(theme, ngZone, _el, _renderer, platform) {
    super(theme, ngZone, platform);
    this._el = _el;
    this._renderer = _renderer;
    this.setAutoContrast();
    this._triggerElement = this._el;
    this._rippleContainer = this._el;
  }
  ngOnChanges() {
    this.updateStyle(this._el);
  }
  ngOnInit() {
    if (!this.bg && !this.hasText) {
      this.bg = DEFAULT_BG;
      this.updateStyle(this._el);
      this._renderer.addClass(this._el.nativeElement, this._theme.addSimpleStyle('lyPaper', {
        display: 'block'
      }));
    }
  }
  ngOnDestroy() {
    this._removeRippleEvents();
  }
  static {
    this.ɵfac = function LyPaper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyPaper)(i0.ɵɵdirectiveInject(LyTheme2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.Platform));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LyPaper,
      selectors: [["ly-paper"], ["", "ly-paper", ""], ["", "ly-text", ""]],
      inputs: {
        bg: "bg",
        color: "color",
        raised: "raised",
        outlined: "outlined",
        elevation: "elevation",
        shadowColor: "shadowColor",
        disableRipple: "disableRipple",
        hasText: [0, "ly-text", "hasText"]
      },
      standalone: false,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyPaper, [{
    type: Directive,
    args: [{
      selector: `ly-paper, [ly-paper], [ly-text]`,
      inputs: ['bg', 'color', 'raised', 'outlined', 'elevation', 'shadowColor', 'disableRipple']
    }]
  }], () => [{
    type: LyTheme2
  }, {
    type: i0.NgZone
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i1.Platform
  }], {
    hasText: [{
      type: Input,
      args: ['ly-text']
    }]
  });
})();
class LyWithClass {
  set withClass(val) {
    if (!val) {
      throw new Error(`'${val}' is not valid className`);
    }
    this.el.nativeElement.classList.add(val);
  }
  constructor(el) {
    this.el = el;
  }
  static {
    this.ɵfac = function LyWithClass_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyWithClass)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LyWithClass,
      selectors: [["", "withClass", ""]],
      inputs: {
        withClass: "withClass"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyWithClass, [{
    type: Directive,
    args: [{
      selector: '[withClass]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    withClass: [{
      type: Input
    }]
  });
})();
class StyleRenderer {
  constructor(_theme, _el, _renderer) {
    this._theme = _theme;
    this._renderer = _renderer;
    this._set = new Set();
    if (_el) {
      this._nEl = _el.nativeElement;
      this._set = new Set();
    }
  }
  /**
   * Build multiple styles and render them in the DOM.
   * @param styles Styles
   * @param applyRootClass If `applyToRoot` is `true` and the root property is defined,
   * it will automatically be added to the component.
   *
   * e.g.
   *
   * ```ts
   * const STYLES = () => ({
   *   root: lyl `{...}`, // this class will be added to the root component
   *   item: lyl `{...}`
   * })
   * ```
   *
   * Also accepts the name of a class.
   *
   * e.g.
   *
   * ```ts
   * renderSheet(STYLES, 'item')
   * ```
   */
  renderSheet(styles, applyRootClass) {
    const classes = this._theme.renderStyleSheet(styles);
    if (applyRootClass === true && classes.root) {
      this.addClass(classes.root);
      return classes;
    }
    if (applyRootClass) {
      const customClass = classes[applyRootClass];
      if (customClass) {
        this.addClass(customClass);
      }
    }
    return classes;
  }
  /**
   * Render style and apply class name to host Component or Directive,
   * require provide `StyleRenderer` in your Component.
   * e.g.
   * @Component({
   *   ...
   *   providers: [ StyleRenderer ]
   * })
   */
  add(id, style, priority, oldClass) {
    const args = arguments;
    /** Class name or keyframe name */
    let className;
    let len = args.length;
    // clean
    if (len === 4 && args[3] == null) {
      len -= 1;
    }
    if (len === 3 && args[2] == null) {
      len -= 1;
    }
    if (len === 1) {
      className = this._theme._createStyleContent2(id, null, null, TypeStyle.LylStyle);
    } else if (len === 2) {
      if (typeof id === 'string') {
        className = this._theme._createStyleContent2(style, id, null, TypeStyle.LylStyle);
      } else if (typeof style === 'number') {
        className = this._theme._createStyleContent2(id, null, style, TypeStyle.LylStyle);
      } else {
        className = this._theme._createStyleContent2(id, null, null, TypeStyle.LylStyle);
        oldClass = style;
      }
    } else if (len === 3) {
      if (typeof id === 'string') {
        if (typeof priority === 'number') {
          // (id, style, priority)
          className = this._theme._createStyleContent2(style, id, priority, TypeStyle.LylStyle);
        } else {
          // (id, style, oldClass)
          className = this._theme._createStyleContent2(style, id, null, TypeStyle.LylStyle);
          oldClass = priority;
        }
      } else {
        // (style, priority, oldClass)
        className = this._theme._createStyleContent2(id, null, style, TypeStyle.LylStyle);
        oldClass = priority;
      }
    } else if (len === 4) {
      className = this._theme._createStyleContent2(style, id, priority, TypeStyle.LylStyle);
    }
    if (this._nEl) {
      return this.updateClass(className, oldClass);
    }
    throw new Error(`StyleRenderer is required on the Component!\n` + `Add provider for StyleRenderer in Component or Directive:\n\n` + `e.g:\n\n` + `@Component({\n` + `  providers: [ StyleRenderer ]\n` + `})\n`);
  }
  /**
   * Only render style and return class name.
   */
  render(styleOrId, priorityOrStyle, priority) {
    if (typeof styleOrId === 'string') {
      return this._theme._createStyleContent2(priorityOrStyle, styleOrId, priority, TypeStyle.LylStyle);
    }
    return this._theme._createStyleContent2(styleOrId, null, priority, TypeStyle.LylStyle);
  }
  addClass(className) {
    if (!this._set.has(className)) {
      this._set.add(className);
      this._renderer.addClass(this._nEl, className);
    }
  }
  removeClass(className) {
    if (className && this._set.has(className)) {
      this._set.delete(className);
      this._renderer.removeClass(this._nEl, className);
    }
  }
  removeClasses(rawClassVal) {
    if (rawClassVal) {
      rawClassVal.forEach(klass => this.removeClass(klass));
    }
  }
  toggleClass(className, enabled) {
    if (enabled) {
      this.addClass(className);
    } else {
      this.removeClass(className);
    }
  }
  updateClass(newClassName, oldClassName) {
    this.removeClass(oldClassName);
    this.addClass(newClassName);
    return newClassName;
  }
  static {
    this.ɵfac = function StyleRenderer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StyleRenderer)(i0.ɵɵinject(LyTheme2), i0.ɵɵinject(i0.ElementRef, 8), i0.ɵɵinject(i0.Renderer2, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StyleRenderer,
      factory: StyleRenderer.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StyleRenderer, [{
    type: Injectable
  }], () => [{
    type: LyTheme2
  }, {
    type: i0.ElementRef,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.Renderer2,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
/**
 * Parameter decorator to be used for create Dynamic style together with `@Input`
 * @param style style
 * @param formatWith A function to format the value
 * @decorator
 */
function Style2(style, formatWith, priority) {
  return function (target, propertyKey, descriptor) {
    target.constructor[propertyKey] = style;
    const _propertyKey = `_${propertyKey}Value`;
    if (descriptor) {
      const set = descriptor.set;
      descriptor.set = function (val) {
        const newValue = formatWith ? formatWith(val) : val;
        createStyle(this, propertyKey, newValue, style, priority);
        set.call(this, newValue);
      };
      if (!descriptor.get) {
        descriptor.get = function () {
          return this[_propertyKey];
        };
      }
    } else {
      Object.defineProperty(target, propertyKey, {
        configurable: true,
        enumerable: true,
        set(val) {
          const newValue = formatWith ? formatWith(val) : val;
          createStyle(this, propertyKey, newValue, style, priority);
        },
        get() {
          return this[_propertyKey];
        }
      });
    }
  };
}
/**
 * Parameter decorator to be used for create Dynamic style together with `@Input`
 * @param style style
 * @param priority priority of style, default: 0
 * @decorator
 */
function Style(style, formatWithOrPriority, priorityOrFormatWith) {
  return function (target, propertyKey, descriptor) {
    let formatWith = null;
    let priority = undefined;
    if (typeof priorityOrFormatWith === 'function' || typeof formatWithOrPriority === 'number') {
      formatWith = priorityOrFormatWith;
      priority = formatWithOrPriority;
    } else {
      formatWith = formatWithOrPriority;
      priority = priorityOrFormatWith;
    }
    target.constructor[propertyKey] = style;
    const _propertyKey = `_${propertyKey}Value`;
    if (descriptor) {
      const set = descriptor.set;
      descriptor.set = function (val) {
        const newValue = formatWith ? formatWith(val) : val;
        createStyle(this, propertyKey, newValue, style, priority);
        set.call(this, val);
      };
      if (!descriptor.get) {
        descriptor.get = function () {
          return this[_propertyKey];
        };
      }
    } else {
      Object.defineProperty(target, propertyKey, {
        configurable: true,
        enumerable: true,
        set(val) {
          createStyle(this, propertyKey, val, style, priority);
        },
        get() {
          return this[_propertyKey];
        }
      });
    }
  };
}
/**
 * Create a responsive style for component with a key
 * @param c The component
 * @param propertyKeyConfig Style key
 * @param value value
 * @param style style template
 * @param priority priority of style
 */
function createStyle(c, propertyKeyConfig, value, style, priority) {
  const propertyKey = typeof propertyKeyConfig === 'string' ? propertyKeyConfig : propertyKeyConfig.key;
  const _propertyKeyClass = `_${propertyKey}Class`;
  const _propertyKey = `_${propertyKey}Value`;
  const oldValue = c[_propertyKey];
  c[_propertyKey] = value;
  if (value === null || value === undefined || value === false) {
    // Remove classes
    const classesForRemove = c[_propertyKeyClass];
    if (classesForRemove && classesForRemove.length) {
      classesForRemove.forEach(className => c.sRenderer.removeClass(className));
    }
  } else if (typeof value === 'string') {
    if (oldValue !== value) {
      c.sRenderer.removeClasses(c[_propertyKeyClass]);
      const values = parseMediaQueriesFromString(value);
      for (let index = 0; index < values.length; index++) {
        const valAndMediaKey = values[index];
        parseMediaQueryFromString(valAndMediaKey).forEach(_ => {
          _renderStyle(c, propertyKeyConfig, _[0], _[1], style, priority);
        });
      }
    }
  } else if (typeof value === 'number' || value === true) {
    if (oldValue !== value) {
      c.sRenderer.removeClasses(c[_propertyKeyClass]);
      _renderStyle(c, propertyKeyConfig, value, null, style, priority);
    }
  } else if (oldValue !== `${value}`) {
    c.sRenderer.removeClasses(c[_propertyKeyClass]);
    // Is array
    for (let index = 0; index < value.length; index++) {
      const val = value[index];
      if (typeof val === 'number' || val === null || val === undefined) {
        _renderStyle(c, propertyKeyConfig, val, null, style, priority);
      } else if (typeof val === 'string') {
        parseMediaQueryFromString(val).forEach(_ => {
          _renderStyle(c, propertyKeyConfig, _[0], _[1], style, priority);
        });
      }
    }
  }
}
function _renderStyle(c, propertyKeyConfig, val, media, style, priority) {
  const propertyKey = typeof propertyKeyConfig === 'string' ? propertyKeyConfig : propertyKeyConfig.key;
  const _propertyKeyClass = `_${propertyKey}Class`;
  const styleTemplate = style(val, media, c);
  const hasMedia = !!media;
  if (styleTemplate == null) {
    // Remove classes
    const classesForRemove = c[_propertyKeyClass];
    if (classesForRemove && classesForRemove.length) {
      classesForRemove.forEach(className => c.sRenderer.removeClass(className));
      c[_propertyKeyClass] = [];
    }
  } else {
    if (c[_propertyKeyClass] === undefined) {
      c[_propertyKeyClass] = [];
    }
    c[_propertyKeyClass].push(c.sRenderer.add(`${typeof propertyKeyConfig === 'string' ? getComponentName(c) : propertyKeyConfig.и}--${propertyKey}-${media ? val + '_' + media : val}`, styleTemplate, getComponentPriority(c, hasMedia ? 99999 : priority), c[_propertyKeyClass]));
  }
}
function getComponentName(comp) {
  return comp.constructor.и || comp.constructor.name || 'unnamed';
}
function getComponentPriority(comp, priority) {
  return priority ?? comp.$priority ?? comp.constructor.$priority ?? 0;
}
const STYLE_PRIORITY$1 = -0.5;
/**
 * @dynamic
 */
class LyStyle {
  set size(value) {
    this.width = value;
    this.height = value;
  }
  constructor(sRenderer) {
    this.sRenderer = sRenderer;
  }
  /** @docs-private */
  static {
    this.и = 'LyStyle';
  }
  static {
    this.$priority = STYLE_PRIORITY$1;
  }
  static {
    this.ɵfac = function LyStyle_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyStyle)(i0.ɵɵdirectiveInject(StyleRenderer));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LyStyle,
      selectors: [["", "lyStyle", ""], ["", "lyColor", ""], ["", "lyBg", ""], ["", "lyP", ""], ["", "lyPf", ""], ["", "lyPe", ""], ["", "lyPt", ""], ["", "lyPb", ""], ["", "lyPx", ""], ["", "lyPy", ""], ["", "lyM", ""], ["", "lyMf", ""], ["", "lyMe", ""], ["", "lyMt", ""], ["", "lyMb", ""], ["", "lyMx", ""], ["", "lyMy", ""], ["", "lySize", ""], ["", "lyWidth", ""], ["", "lyMaxWidth", ""], ["", "lyMinWidth", ""], ["", "lyHeight", ""], ["", "lyMaxHeight", ""], ["", "lyMinHeight", ""], ["", "lyDisplay", ""], ["", "lyFlex", ""], ["", "lyFlexBasis", ""], ["", "lyFlexDirection", ""], ["", "lyFlexGrow", ""], ["", "lyFlexSelf", ""], ["", "lyFlexShrink", ""], ["", "lyFlexWrap", ""], ["", "lyJustifyContent", ""], ["", "lyJustifyItems", ""], ["", "lyJustifySelf", ""], ["", "lyAlignContent", ""], ["", "lyAlignItems", ""], ["", "lyOrder", ""]],
      inputs: {
        lyStyle: "lyStyle",
        color: [0, "lyColor", "color"],
        bg: [0, "lyBg", "bg"],
        p: [0, "lyP", "p"],
        pf: [0, "lyPf", "pf"],
        pe: [0, "lyPe", "pe"],
        pt: [0, "lyPt", "pt"],
        pb: [0, "lyPb", "pb"],
        px: [0, "lyPx", "px"],
        py: [0, "lyPy", "py"],
        m: [0, "lyM", "m"],
        mf: [0, "lyMf", "mf"],
        me: [0, "lyMe", "me"],
        mt: [0, "lyMt", "mt"],
        mb: [0, "lyMb", "mb"],
        mx: [0, "lyMx", "mx"],
        my: [0, "lyMy", "my"],
        size: [0, "lySize", "size"],
        width: [0, "lyWidth", "width"],
        maxWidth: [0, "lyMaxWidth", "maxWidth"],
        minWidth: [0, "lyMinWidth", "minWidth"],
        height: [0, "lyHeight", "height"],
        maxHeight: [0, "lyMaxHeight", "maxHeight"],
        minHeight: [0, "lyMinHeight", "minHeight"],
        display: [0, "lyDisplay", "display"],
        flex: [0, "lyFlex", "flex"],
        flexBasis: [0, "lyFlexBasis", "flexBasis"],
        flexDirection: [0, "lyFlexDirection", "flexDirection"],
        flexGrow: [0, "lyFlexGrow", "flexGrow"],
        flexSelf: [0, "lyFlexSelf", "flexSelf"],
        flexShrink: [0, "lyFlexShrink", "flexShrink"],
        flexWrap: [0, "lyFlexWrap", "flexWrap"],
        justifyContent: [0, "lyJustifyContent", "justifyContent"],
        justifyItems: [0, "lyJustifyItems", "justifyItems"],
        justifySelf: [0, "lyJustifySelf", "justifySelf"],
        alignContent: [0, "lyAlignContent", "alignContent"],
        alignItems: [0, "lyAlignItems", "alignItems"],
        order: [0, "lyOrder", "order"],
        elevation: [0, "lyElevation", "elevation"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([StyleRenderer])]
    });
  }
}
__decorate([Style(value => theme => _className => `${_className}{color:${theme.colorOf(value)};}`)], LyStyle.prototype, "color", void 0);
__decorate([Style(value => theme => _className => `${_className}{background:${theme.colorOf(value)};}`)], LyStyle.prototype, "bg", void 0);
__decorate([Style((value, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{padding:${to8Px(value)};}}`)], LyStyle.prototype, "p", void 0);
__decorate([Style((val, media) => ({
  breakpoints,
  after
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{padding-${after}:${to8Px(val)};}}`)], LyStyle.prototype, "pf", void 0);
__decorate([Style((val, media) => ({
  breakpoints,
  before
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{padding-${before}:${to8Px(val)};}}`)], LyStyle.prototype, "pe", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{padding-top:${to8Px(val)};}}`)], LyStyle.prototype, "pt", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{padding-bottom:${to8Px(val)};}}`)], LyStyle.prototype, "pb", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{padding:0 ${to8Px(val)};}}`)], LyStyle.prototype, "px", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{padding:${to8Px(val)} 0;}}`)], LyStyle.prototype, "py", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{margin:${to8Px(val)};}}`)], LyStyle.prototype, "m", void 0);
__decorate([Style((val, media) => ({
  breakpoints,
  after
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{margin-${after}:${to8Px(val)};}}`)], LyStyle.prototype, "mf", void 0);
__decorate([Style((val, media) => ({
  breakpoints,
  before
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{margin-${before}:${to8Px(val)};}}`)], LyStyle.prototype, "me", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{margin-top:${to8Px(val)};}}`)], LyStyle.prototype, "mt", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{margin-bottom:${to8Px(val)};}}`)], LyStyle.prototype, "mb", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{margin:0 ${to8Px(val)};}}`)], LyStyle.prototype, "mx", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{margin:${to8Px(val)} 0;}}`)], LyStyle.prototype, "my", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{width:${transform(val)};}}`)], LyStyle.prototype, "width", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{max-width:${transform(val)};}}`)], LyStyle.prototype, "maxWidth", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{min-width:${transform(val)};}}`)], LyStyle.prototype, "minWidth", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{height:${transform(val)};}}`)], LyStyle.prototype, "height", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{max-height:${transform(val)};}}`)], LyStyle.prototype, "maxHeight", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{min-height:${transform(val)};}}`)], LyStyle.prototype, "minHeight", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{display:${val};}}`)], LyStyle.prototype, "display", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{flex:${val};}}`)], LyStyle.prototype, "flex", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{flex-basis:${val};}}`)], LyStyle.prototype, "flexBasis", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{flex-direction:${val};}}`)], LyStyle.prototype, "flexDirection", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{flex-grow:${val};}}`)], LyStyle.prototype, "flexGrow", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{flex-self:${val};}}`)], LyStyle.prototype, "flexSelf", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{flex-shrink:${val};}}`)], LyStyle.prototype, "flexShrink", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{flex-wrap:${val};}}`)], LyStyle.prototype, "flexWrap", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{justify-content:${val};}}`)], LyStyle.prototype, "justifyContent", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{justify-items:${val};}}`)], LyStyle.prototype, "justifyItems", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{justify-self:${val};}}`)], LyStyle.prototype, "justifySelf", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{align-content:${val};}}`)], LyStyle.prototype, "alignContent", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{align-items:${val};}}`)], LyStyle.prototype, "alignItems", void 0);
__decorate([Style((val, media) => ({
  breakpoints
}) => _className => `@media ${media && breakpoints[media] || 'all'}{${_className}{order:${val};}}`)], LyStyle.prototype, "order", void 0);
__decorate([Style((value, media) => ({
  breakpoints
}) => _className => `@media ${media && (breakpoints[media] || media) || 'all'}{${_className}{${value};}}`)], LyStyle.prototype, "lyStyle", void 0);
__decorate([Style2((value, media) => ({
  breakpoints,
  shadow
}) => _className => `@media ${media && (breakpoints[media] || media) || 'all'}{${_className}{box-shadow:${shadowBuilder(value, shadow)};}}`, coerceNumberProperty)], LyStyle.prototype, "elevation", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyStyle, [{
    type: Directive,
    args: [{
      selector: `[lyStyle],
              [lyColor],
              [lyBg],
              [lyP], [lyPf], [lyPe], [lyPt], [lyPb], [lyPx], [lyPy],
              [lyM], [lyMf], [lyMe], [lyMt], [lyMb], [lyMx], [lyMy],
              [lySize],
              [lyWidth], [lyMaxWidth], [lyMinWidth],
              [lyHeight], [lyMaxHeight], [lyMinHeight],
              [lyDisplay],
              [lyFlex],
              [lyFlexBasis],
              [lyFlexDirection],
              [lyFlexGrow],
              [lyFlexSelf],
              [lyFlexShrink],
              [lyFlexWrap],
              [lyJustifyContent],
              [lyJustifyItems],
              [lyJustifySelf],
              [lyAlignContent],
              [lyAlignItems],
              [lyOrder]`,
      providers: [StyleRenderer],
      inputs: ['lyStyle', 'color: lyColor', 'bg: lyBg', 'p: lyP', 'pf: lyPf', 'pe: lyPe', 'pt: lyPt', 'pb: lyPb', 'px: lyPx', 'py: lyPy', 'm: lyM', 'mf: lyMf', 'me: lyMe', 'mt: lyMt', 'mb: lyMb', 'mx: lyMx', 'my: lyMy', 'size: lySize', 'width: lyWidth', 'maxWidth: lyMaxWidth', 'minWidth: lyMinWidth', 'height: lyHeight', 'maxHeight: lyMaxHeight', 'minHeight: lyMinHeight', 'display: lyDisplay', 'flex: lyFlex', 'flexBasis: lyFlexBasis', 'flexDirection: lyFlexDirection', 'flexGrow: lyFlexGrow', 'flexSelf: lyFlexSelf', 'flexShrink: lyFlexShrink', 'flexWrap: lyFlexWrap', 'justifyContent: lyJustifyContent', 'justifyItems: lyJustifyItems', 'justifySelf: lyJustifySelf', 'alignContent: lyAlignContent', 'alignItems: lyAlignItems', 'order: lyOrder', 'elevation: lyElevation']
    }]
  }], () => [{
    type: StyleRenderer
  }], {
    color: [],
    bg: [],
    p: [],
    pf: [],
    pe: [],
    pt: [],
    pb: [],
    px: [],
    py: [],
    m: [],
    mf: [],
    me: [],
    mt: [],
    mb: [],
    mx: [],
    my: [],
    width: [],
    maxWidth: [],
    minWidth: [],
    height: [],
    maxHeight: [],
    minHeight: [],
    display: [],
    flex: [],
    flexBasis: [],
    flexDirection: [],
    flexGrow: [],
    flexSelf: [],
    flexShrink: [],
    flexWrap: [],
    justifyContent: [],
    justifyItems: [],
    justifySelf: [],
    alignContent: [],
    alignItems: [],
    order: [],
    lyStyle: [],
    elevation: []
  });
})();
/**
 * @dynamic
 * @deprecated
 * Spacing
 * [p], [pf], [pe], [pt], [pb], [px], [py],
 * [m], [mf], [me], [mt], [mb], [mx], [my],
 * Sizing
 * [size],
 * [width], [maxWidth], [minWidth],
 * [height], [maxHeight], [minHeight],
 * Others
 * [lyStyle]
 */
class LyStyleDeprecated extends LyStyle {
  constructor(sRenderer, _el) {
    super(sRenderer);
    this._el = _el;
  }
  ngOnChanges(changes) {
    if (isDevMode()) {
      for (const key in changes) {
        if (changes.hasOwnProperty(key)) {
          const message = `[${key}] is deprecated, use [ly${key.charAt(0).toUpperCase() + key.slice(1)}] instead.`;
          console.warn({
            message,
            element: this._el.nativeElement
          });
        }
      }
    }
  }
  static {
    this.ɵfac = function LyStyleDeprecated_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyStyleDeprecated)(i0.ɵɵdirectiveInject(StyleRenderer), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LyStyleDeprecated,
      selectors: [["", "p", ""], ["", "pf", ""], ["", "pe", ""], ["", "pt", ""], ["", "pb", ""], ["", "px", ""], ["", "py", ""], ["", "m", ""], ["", "mf", ""], ["", "me", ""], ["", "mt", ""], ["", "mb", ""], ["", "mx", ""], ["", "my", ""], ["", "size", "", 3, "ly-button", ""], ["", "width", "", 5, "svg", 5, "canvas", 5, "embed", 5, "iframe", 5, "img", 5, "input", 5, "object", 5, "video"], ["", "maxWidth", ""], ["", "minWidth", ""], ["", "height", "", 5, "svg", 5, "canvas", 5, "embed", 5, "iframe", 5, "img", 5, "input", 5, "object", 5, "video"], ["", "maxHeight", ""], ["", "minHeight", ""], ["", "display", ""], ["", "flex", ""], ["", "flexBasis", ""], ["", "flexDirection", ""], ["", "flexGrow", ""], ["", "flexSelf", ""], ["", "flexShrink", ""], ["", "flexWrap", ""], ["", "justifyContent", ""], ["", "justifyItems", ""], ["", "justifySelf", ""], ["", "alignContent", ""], ["", "alignItems", ""], ["", "order", ""]],
      inputs: {
        p: "p",
        pf: "pf",
        pe: "pe",
        pt: "pt",
        pb: "pb",
        px: "px",
        py: "py",
        m: "m",
        mf: "mf",
        me: "me",
        mt: "mt",
        mb: "mb",
        mx: "mx",
        my: "my",
        size: "size",
        width: "width",
        maxWidth: "maxWidth",
        minWidth: "minWidth",
        height: "height",
        maxHeight: "maxHeight",
        minHeight: "minHeight",
        display: "display",
        flex: "flex",
        flexBasis: "flexBasis",
        flexDirection: "flexDirection",
        flexGrow: "flexGrow",
        flexSelf: "flexSelf",
        flexShrink: "flexShrink",
        flexWrap: "flexWrap",
        justifyContent: "justifyContent",
        justifyItems: "justifyItems",
        justifySelf: "justifySelf",
        alignContent: "alignContent",
        alignItems: "alignItems",
        order: "order"
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([StyleRenderer]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyStyleDeprecated, [{
    type: Directive,
    args: [{
      selector: `
              [p], [pf], [pe], [pt], [pb], [px], [py],
              [m], [mf], [me], [mt], [mb], [mx], [my],
              [size]:not([ly-button]),
              [width]:not(svg):not(canvas):not(embed):not(iframe):not(img):not(input):not(object):not(video),
              [maxWidth], [minWidth],
              [height]:not(svg):not(canvas):not(embed):not(iframe):not(img):not(input):not(object):not(video),
              [maxHeight], [minHeight],
              [display],
              [flex],
              [flexBasis],
              [flexDirection],
              [flexGrow],
              [flexSelf],
              [flexShrink],
              [flexWrap],
              [justifyContent],
              [justifyItems],
              [justifySelf],
              [alignContent],
              [alignItems],
              [order]`,
      providers: [StyleRenderer],
      inputs: ['p', 'pf', 'pe', 'pt', 'pb', 'px', 'py', 'm', 'mf', 'me', 'mt', 'mb', 'mx', 'my', 'size', 'width', 'maxWidth', 'minWidth', 'height', 'maxHeight', 'minHeight', 'display', 'flex', 'flexBasis', 'flexDirection', 'flexGrow', 'flexSelf', 'flexShrink', 'flexWrap', 'justifyContent', 'justifyItems', 'justifySelf', 'alignContent', 'alignItems', 'order']
    }]
  }], () => [{
    type: StyleRenderer
  }, {
    type: i0.ElementRef
  }], null);
})();
/**
 * Convert to px if the value is a number, otherwise leave it as is
 * @docs-private
 */
function to8Px(val) {
  return typeof val === 'number' ? `${val * 8}px` : val.includes(' ') ? val.split(' ').map(_ => strTo8Px(_)).join(' ') : strTo8Px(val);
}
function strTo8Px(val) {
  const num = +val;
  return isNaN(num) ? val : `${num * 8}px`;
}
function strToPx(val) {
  const num = +val;
  return isNaN(num) ? val : `${num}px`;
}
function transform(value) {
  return Number(value) <= 1 ? `${value * 100}%` : typeof value === 'number' ? `${value}px` : value.includes(' ') ? value.split(' ').map(_ => strToPx(_)).join(' ') : strToPx(value);
}
class LyCommonModule {
  static {
    this.ɵfac = function LyCommonModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyCommonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LyCommonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyCommonModule, [{
    type: NgModule,
    args: [{
      declarations: [LyStyle, LyWithClass, LyPaper],
      exports: [LyStyle, LyWithClass, LyPaper]
    }]
  }], null, null);
})();
/**
 * @deprecated
 */
class LyStyleDeprecatedModule {
  static {
    this.ɵfac = function LyStyleDeprecatedModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyStyleDeprecatedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LyStyleDeprecatedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyStyleDeprecatedModule, [{
    type: NgModule,
    args: [{
      exports: [LyStyleDeprecated],
      declarations: [LyStyleDeprecated]
    }]
  }], null, null);
})();
function toBoolean(value) {
  return value != null && `${value}` !== 'false';
}
function defaultEntry(value, defaultValue) {
  return value !== '' && value !== void 0 ? value : defaultValue;
}

/**
 * @experimental
 * Element to move, time in ms to animate
 */
function scrollTo(element, duration) {
  let e = document.documentElement;
  if (e.scrollTop === 0) {
    const t = e.scrollTop;
    ++e.scrollTop;
    e = t + 1 === e.scrollTop-- ? e : document.body;
  }
  scrollToFromTo(e, e.scrollTop, element, duration);
}
// Element to move, element or px from, element or px to, time in ms to animate
function scrollToFromTo(element, from, to, duration) {
  if (duration <= 0) {
    return;
  }
  if (typeof from === 'object') {
    from = from.offsetTop;
  }
  if (typeof to === 'object') {
    to = to.offsetTop;
  }
  createScrollWithAnimation(element, from, to, 0, 1 / duration, 20, easeOutCuaic);
}
/**
 * @experimental
 */
function scrollWithAnimation(element, to, duration, p, motion) {
  const _motion = motion || easeOutCuaic;
  const {
    scrollLeft
  } = element;
  return createScrollWithAnimation(element, scrollLeft, to, 0, 1 / duration, 20, _motion, p);
}
function createScrollWithAnimation(element, xFrom, xTo, t01, speed, step, motion, p) {
  const scrollT = p === 'y' ? 'scrollTop' : 'scrollLeft';
  if (t01 < 0 || t01 > 1 || speed <= 0) {
    element[scrollT] = xTo;
    return;
  }
  element[scrollT] = xFrom - (xFrom - xTo) * motion(t01);
  t01 += speed * step;
  setTimeout(() => {
    createScrollWithAnimation(element, xFrom, xTo, t01, speed, step, motion, p);
  }, step);
}
// function linearTween(t: number) {
//   return t;
// }
// function easeInQuad(t: number) {
//   return t * t;
// }
// function easeOutQuad(t: number) {
//   return -t * (t - 2);
// }
// function easeInOutQuad(t: number) {
//   t /= 0.5;
//   if (t < 1) {return t * t / 2; }
//   t--;
//   return (t * (t - 2) - 1) / 2;
// }
// function easeInCuaic(t: number) {
//   return t * t * t;
// }
function easeOutCuaic(t) {
  t--;
  return t * t * t + 1;
}
// function easeInOutCuaic(t: number) {
//   t /= 0.5;
//   if (t < 1) {return t * t * t / 2; }
//   t -= 2;
//   return (t * t * t + 2) / 2;
// }
// function easeInQuart(t: number) {
//   return t * t * t * t;
// }
// function easeOutQuart(t: number) {
//   t--;
//   return -(t * t * t * t - 1);
// }
// function easeInOutQuart(t: number) {
//   t /= 0.5;
//   if (t < 1) {return 0.5 * t * t * t * t; }
//   t -= 2;
//   return -(t * t * t * t - 2) / 2;
// }
// function easeInQuint(t: number) {
//   return t * t * t * t * t;
// }
// function easeOutQuint(t: number) {
//   t--;
//   return t * t * t * t * t + 1;
// }
// function easeInOutQuint(t: number) {
//   t /= 0.5;
//   if (t < 1) {return t * t * t * t * t / 2; }
//   t -= 2;
//   return (t * t * t * t * t + 2) / 2;
// }
// function easeInSine(t: number) {
//   return -Math.cos(t / (Math.PI / 2)) + 1;
// }
// function easeOutSine(t: number) {
//   return Math.sin(t / (Math.PI / 2));
// }
// function easeInOutSine(t: number) {
//   return -(Math.cos(Math.PI * t) - 1) / 2;
// }
// function easeInExpo(t: number) {
//   return Math.pow(2, 10 * (t - 1));
// }
// function easeOutExpo(t: number) {
//   return -Math.pow(2, -10 * t) + 1;
// }
// function easeInOutExpo(t: number) {
//   t /= 0.5;
//   if (t < 1) {return Math.pow(2, 10 * (t - 1)) / 2; }
//   t--;
//   return (-Math.pow(2, -10 * t) + 2) / 2;
// }
// function easeInCirc(t: number) {
//   return -Math.sqrt(1 - t * t) - 1;
// }
// function easeOutCirc(t: number) {
//   t--;
//   return Math.sqrt(1 - t * t);
// }
// function easeInOutCirc(t: number) {
//   t /= 0.5;
//   if (t < 1) {return -(Math.sqrt(1 - t * t) - 1) / 2; }
//   t -= 2;
//   return (Math.sqrt(1 - t * t) + 1) / 2;
// }

function toNumber(val, _default) {
  const num = typeof val === 'number' ? val : typeof val === 'string' && val.length ? +val : _default;
  return isNaN(num) ? _default === void 0 ? 0 : _default : num;
}
function componentDestroyed(component) {
  const modifiedComponent = component;
  if (modifiedComponent.__componentDestroyed$) {
    return modifiedComponent.__componentDestroyed$;
  }
  const oldNgOnDestroy = component.ngOnDestroy;
  const stop$ = new ReplaySubject();
  modifiedComponent.ngOnDestroy = function () {
    if (oldNgOnDestroy) {
      oldNgOnDestroy.apply(component);
    }
    stop$.next();
    stop$.complete();
  };
  return modifiedComponent.__componentDestroyed$ = stop$.asObservable();
}
function untilComponentDestroyed(component) {
  return source => source.pipe(takeUntil(componentDestroyed(component)));
}
class LyHostClass {
  constructor(_el, _renderer) {
    this._renderer = _renderer;
    this._set = new Set();
    this._nEl = _el.nativeElement;
    console.log(`LyHostClass: Deprecated use instead StyleRenderer`);
  }
  add(className) {
    if (!this._set.has(className)) {
      this._set.add(className);
      this._renderer.addClass(this._nEl, className);
    }
  }
  remove(className) {
    if (className && this._set.has(className)) {
      this._set.delete(className);
      this._renderer.removeClass(this._nEl, className);
    }
  }
  toggle(className, enabled) {
    if (enabled) {
      this.add(className);
    } else {
      this.remove(className);
    }
  }
  update(newClassName, oldClassName) {
    this.remove(oldClassName);
    this.add(newClassName);
    return newClassName;
  }
  static {
    this.ɵfac = function LyHostClass_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyHostClass)(i0.ɵɵinject(i0.ElementRef), i0.ɵɵinject(i0.Renderer2));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyHostClass,
      factory: LyHostClass.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyHostClass, [{
    type: Injectable
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], null);
})();
var FocusStatus;
(function (FocusStatus) {
  /**mouse and/or touch*/
  FocusStatus["DEFAULT"] = "default";
  /** keyboard and/or program*/
  FocusStatus["KEYBOARD"] = "keyboard";
})(FocusStatus || (FocusStatus = {}));
class LyFocusState {
  constructor(_ngZone, _platform) {
    this._ngZone = _ngZone;
    this._platform = _platform;
    this._elementMap = new Map();
    this._count = 0;
  }
  listen(element, keyElement) {
    if (!this._platform.isBrowser) {
      // return null if it is not browser platform
      return null;
    }
    const nativeElement = getNativeElement(element);
    const key = keyElement && getNativeElement(keyElement) || nativeElement;
    if (this._elementMap.has(key)) {
      return this._elementMap.get(key).subject.asObservable();
    }
    const focusState = {
      unlisten: null,
      subject: new Subject()
    };
    this._incrementCount();
    const focusListener = event => this._on(event, focusState.subject);
    const blurListener = event => this._on(event, focusState.subject);
    focusState.unlisten = () => {
      nativeElement.removeEventListener('focus', focusListener, true);
      nativeElement.removeEventListener('blur', blurListener, true);
    };
    this._elementMap.set(key, focusState);
    this._ngZone.runOutsideAngular(() => {
      nativeElement.addEventListener('focus', focusListener, true);
      nativeElement.addEventListener('blur', blurListener, true);
    });
    return focusState.subject.asObservable();
  }
  focusElement(element, origin, options) {
    const nativeElement = getNativeElement(element);
    this._currentEvent = origin;
    if (typeof nativeElement.focus === 'function') {
      nativeElement.focus(options);
    }
  }
  unlisten(element) {
    if (!this._platform.isBrowser) {
      return;
    }
    const el = getNativeElement(element);
    const focusStateInfo = this._elementMap.get(el);
    if (focusStateInfo) {
      focusStateInfo.unlisten();
      this._elementMap.delete(el);
      this._decrementCount();
    }
  }
  _on(event, subject) {
    let by = null;
    if (event.type === 'focus') {
      by = this._currentEvent || 'keyboard';
    }
    this._ngZone.run(() => subject.next(by));
  }
  _addGlobalListeners() {
    if (!this._platform.isBrowser) {
      return;
    }
    const eventListenerOptions = supportsPassiveEventListeners() ? {
      passive: true,
      capture: true
    } : false;
    const documentKeydownListener = () => this._ngZone.runOutsideAngular(() => this._currentEvent = 'keyboard');
    const documentMousedownListener = () => this._ngZone.runOutsideAngular(() => this._currentEvent = 'mouse');
    this._ngZone.runOutsideAngular(() => {
      document.addEventListener('keydown', documentKeydownListener, eventListenerOptions);
      document.addEventListener('mousedown', documentMousedownListener, eventListenerOptions);
    });
    this._removeGlobalListeners = () => {
      document.removeEventListener('keydown', documentKeydownListener, eventListenerOptions);
      document.removeEventListener('mousedown', documentMousedownListener, eventListenerOptions);
    };
  }
  _incrementCount() {
    if (++this._count === 1) {
      this._addGlobalListeners();
    }
  }
  _decrementCount() {
    if (! --this._count) {
      this._removeGlobalListeners();
    }
  }
  ngOnDestroy() {
    this._elementMap.forEach((_, element) => this.unlisten(element));
  }
  static {
    this.ɵfac = function LyFocusState_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyFocusState)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.Platform));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyFocusState,
      factory: LyFocusState.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyFocusState, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: i1.Platform
  }], null);
})();
const AUI_VERSION = '17.0.0';
const AUI_LAST_UPDATE = '2024-10-13T03:32:19.832Z';
const LY_HAMMER_OPTIONS = new InjectionToken('LY_HAMMER_OPTIONS');
const HAMMER_GESTURES_EVENTS = ['slide', 'slidestart', 'slideend', 'slideright', 'slideleft', 'slidecancel'];
/**
 * Fake HammerInstance that is used when a Hammer instance is requested when HammerJS has not
 * been loaded on the page.
 */
const noopHammerInstance = {
  on: () => {},
  off: () => {}
};
class LyHammerGestureConfig extends HammerGestureConfig {
  constructor(_hammerOptions) {
    super();
    this._hammerOptions = _hammerOptions;
    this.events = HAMMER_GESTURES_EVENTS;
  }
  buildHammer(element) {
    const hammer = typeof window !== 'undefined' ? window.Hammer : null;
    if (!hammer) {
      return noopHammerInstance;
    }
    const mc = new hammer(element, this._hammerOptions || {});
    const pan = new hammer.Pan();
    const swipe = new hammer.Swipe();
    const slide = this._createRecognizer(pan, {
      event: 'slide',
      threshold: 0
    }, swipe);
    pan.recognizeWith(swipe);
    // Add customized gestures to Hammer manager
    mc.add([swipe, pan, slide]);
    return mc;
  }
  /** Creates a new recognizer, without affecting the default recognizers of HammerJS */
  _createRecognizer(base, options, ...inheritances) {
    const recognizer = new base.constructor(options);
    inheritances.push(base);
    inheritances.forEach(item => recognizer.recognizeWith(item));
    return recognizer;
  }
  static {
    this.ɵfac = function LyHammerGestureConfig_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyHammerGestureConfig)(i0.ɵɵinject(LY_HAMMER_OPTIONS, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyHammerGestureConfig,
      factory: LyHammerGestureConfig.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyHammerGestureConfig, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LY_HAMMER_OPTIONS]
    }]
  }], null);
})();
class LyThemeModule {
  static setTheme(themeName) {
    return {
      ngModule: LyThemeModule,
      providers: [[LyTheme2], [StyleRenderer], {
        provide: LY_THEME_NAME,
        useValue: themeName
      }]
    };
  }
  static {
    this.ɵfac = function LyThemeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyThemeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LyThemeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyThemeModule, [{
    type: NgModule
  }], null, null);
})();
class Undefined {
  constructor() {}
}
const UndefinedValue = new Undefined();

// @Injectable()
class LyOverlayRef {}
const styles = theme => {
  return {
    overlay: _className => `${_className}{position:fixed;top:0;left:0;right:0;bottom:0;z-index:${theme.zIndex.overlay};pointer-events:none;}`
  };
};
class LyOverlayContainer {
  get overlayLen() {
    return this._items.size;
  }
  constructor(theme, _platform, _ngZone, document) {
    this.theme = theme;
    this._platform = _platform;
    this._ngZone = _ngZone;
    this._classes = this.theme.addStyleSheet(styles);
    this._items = new Set();
    this._overlays = new Set();
    /** Keyboard event listener that will be attached to the body. */
    this._keydownListener = event => {
      const overlays = Array.from(this._overlays);
      for (let i = overlays.length - 1; i > -1; i--) {
        const observed = overlays[i]._keydownEvents.observers.length > 0;
        if (observed) {
          const keydownEvents = overlays[i]._keydownEvents;
          this._ngZone.run(() => keydownEvents.next(event));
          break;
        }
      }
    };
    this._document = document;
    if (this._platform.isBrowser) {
      const container = document.createElement('ly-overlay-container');
      document.body.appendChild(container);
      this._containerElement = container;
    }
  }
  get containerElement() {
    return this._containerElement;
  }
  addOverlay(overlayRef) {
    this._overlays.add(overlayRef);
    if (!this._isAttached) {
      this._ngZone.runOutsideAngular(() => this._document.body.addEventListener('keydown', this._keydownListener));
      this._isAttached = true;
    }
  }
  removeOverlay(overlayRef) {
    this._overlays.delete(overlayRef);
    if (!this._overlays.size && this._isAttached) {
      this._document.body.removeEventListener('keydown', this._keydownListener);
      this._isAttached = false;
    }
  }
  /**
   * Add instance
   * @ignore
   */
  _addElement(item) {
    this._items.add(item);
    this.containerElement.appendChild(item);
    this._update();
  }
  /**
  * Remove instance
  * @ignore
  */
  _removeElement(item) {
    this.containerElement.removeChild(item);
    this._items.delete(item);
    this._update();
  }
  /**
   * Update styles for overlay container
   * @ignore
   */
  _update() {
    if (this._items.size) {
      if (!this._isActiveOverlayContainer) {
        this._isActiveOverlayContainer = true;
        this._containerElement.classList.add(this._classes.overlay);
      }
    } else if (this._isActiveOverlayContainer) {
      this._containerElement.classList.remove(this._classes.overlay);
      this._isActiveOverlayContainer = false;
    }
  }
  static {
    this.ɵfac = function LyOverlayContainer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyOverlayContainer)(i0.ɵɵinject(LyTheme2), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyOverlayContainer,
      factory: LyOverlayContainer.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyOverlayContainer, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: LyTheme2
  }, {
    type: i1.Platform
  }, {
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
class LyOverlayConfig {
  constructor() {
    this.hasBackdrop = true;
    /**
     * Whether the user can click on the backdrop to close the overlay.
     */
    this.disableClose = false;
  }
}
const STYLE_PRIORITY = -2;
const STYLES_BACKDROP_ROOT = () => _className => `${st2c(LY_COMMON_STYLES.fill, `${_className}`)}${_className}{width:100vw;height:100vh;pointer-events:all;user-select:none;}`;
class LyOverlayBackdrop {
  onclick() {
    if (!this._config.disableClose) {
      this._config.fnDestroy();
    }
  }
  constructor(_el, _theme, sRenderer, _config) {
    this._el = _el;
    this.sRenderer = sRenderer;
    this._config = _config;
    _el.nativeElement.classList.add(sRenderer.add(STYLES_BACKDROP_ROOT, STYLE_PRIORITY));
    // this applies custom class for backdrop,
    // if one is not defined, do nothing.
    const backdropClass = _config.backdropClass;
    if (backdropClass) {
      this._el.nativeElement.classList.add(backdropClass);
    }
  }
  static {
    this.ɵfac = function LyOverlayBackdrop_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyOverlayBackdrop)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(LyTheme2), i0.ɵɵdirectiveInject(StyleRenderer), i0.ɵɵdirectiveInject(LyOverlayConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LyOverlayBackdrop,
      selectors: [["ly-overlay-backdrop"]],
      hostBindings: function LyOverlayBackdrop_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function LyOverlayBackdrop_click_HostBindingHandler() {
            return ctx.onclick();
          });
        }
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([StyleRenderer])],
      decls: 0,
      vars: 0,
      template: function LyOverlayBackdrop_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyOverlayBackdrop, [{
    type: Component,
    args: [{
      selector: 'ly-overlay-backdrop',
      template: ``,
      providers: [StyleRenderer]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: LyTheme2
  }, {
    type: StyleRenderer
  }, {
    type: LyOverlayConfig
  }], {
    onclick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
function createOverlayInjector(parent, config, overlayFactory) {
  return Injector.create({
    providers: [{
      provide: LyOverlayConfig,
      useValue: config
    }, {
      provide: LyOverlayRef,
      useValue: overlayFactory
    }],
    parent
  });
}
class OverlayFactory {
  get injector() {
    return this._newInjector;
  }
  get containerElement() {
    return this._el;
  }
  get backdropElement() {
    return this._backdropElement;
  }
  get componentRef() {
    return this._compRef;
  }
  get isDestroyed() {
    return this._isRemoved;
  }
  constructor(_componentFactoryResolver, _appRef, _templateRefOrComponent, _overlayContainer, _context, _injector, _scrollDispatcher, _viewportRuler, _config) {
    this._componentFactoryResolver = _componentFactoryResolver;
    this._appRef = _appRef;
    this._overlayContainer = _overlayContainer;
    this._injector = _injector;
    /** Stream of keydown events dispatched to this overlay. */
    this._keydownEvents = new Subject();
    this._windowSRSub = Subscription.EMPTY;
    this._isDetached = false;
    this._isRemoved = false;
    const config = {
      ...new LyOverlayConfig(),
      ..._config
    };
    this._el = document.createElement('div');
    const __styles = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none'
    };
    if (config) {
      Object.assign(__styles, config.styles);
    }
    const newInjector = this._newInjector = createOverlayInjector(config.injector || this._injector, {
      fnDestroy: this.destroy.bind(this),
      ...config,
      styles: __styles
    }, this);
    this._updateStyles(__styles);
    if (config) {
      if (config.onResizeScroll) {
        this.onResizeScroll = config.onResizeScroll;
      }
      this._windowSRSub = merge(_scrollDispatcher.scrolled(0), _viewportRuler.change()).subscribe(() => {
        if (this.onResizeScroll) {
          this.onResizeScroll();
        }
      });
      if (config.classes) {
        const classes = config.classes;
        classes.forEach(className => this._el.classList.add(className));
      }
    }
    this.updateBackdrop(!!config.hasBackdrop);
    this._appendComponentToBody(_templateRefOrComponent, _context, newInjector);
    this._updateBackdropPosition();
  }
  updateBackdrop(hasBackdrop) {
    if (hasBackdrop) {
      if (this._compRefOverlayBackdrop) {
        return;
      }
      this._compRefOverlayBackdrop = this._generateComponent(LyOverlayBackdrop, this._newInjector);
      this._appRef.attachView(this._compRefOverlayBackdrop.hostView);
      this._backdropElement = this._compRefOverlayBackdrop.location.nativeElement;
      this._overlayContainer._addElement(this._backdropElement);
    } else if (this._compRefOverlayBackdrop) {
      this._appRef.detachView(this._compRefOverlayBackdrop.hostView);
      this._backdropElement = this._compRefOverlayBackdrop.location.nativeElement;
      this._overlayContainer._removeElement(this._backdropElement);
      this._compRefOverlayBackdrop = null;
    }
    this._updateBackdropPosition();
  }
  /** Gets an observable of keydown events targeted to this overlay. */
  keydownEvents() {
    return this._keydownEvents;
  }
  _updateStyles(__styles) {
    /** Apply styles */
    /** set styles */
    for (const key in __styles) {
      if (__styles.hasOwnProperty(key)) {
        const styleVal = __styles[key];
        if (styleVal != null) {
          this._el.style[key] = typeof __styles[key] === 'number' ? `${styleVal}px` : styleVal;
        }
      }
    }
  }
  _appendComponentToBody(type, context, injector) {
    if (type instanceof TemplateRef) {
      // Create a component reference from the component
      const viewRef = this._viewRef = type.createEmbeddedView(context || {});
      this._appRef.attachView(viewRef);
      // Get DOM element from component
      viewRef.rootNodes.forEach(_ => this._el.appendChild(_));
      // Append DOM element to the body
      this._overlayContainer._addElement(this._el);
    } else if (typeof type === 'string') {
      this._el.innerText = type;
      this._overlayContainer._addElement(this._el);
    } else {
      this._compRef = this._generateComponent(type, injector);
      this._appRef.attachView(this._compRef.hostView);
      this._el.appendChild(this._compRef.location.nativeElement);
      this._overlayContainer._addElement(this._el);
    }
    this._overlayContainer.addOverlay(this);
  }
  _updateBackdropPosition() {
    const container = this._overlayContainer.containerElement;
    if (this._backdropElement?.parentElement === container && this._el?.parentElement === container) {
      this._overlayContainer.containerElement.insertBefore(this._backdropElement, this._el);
    }
  }
  _generateComponent(type, injector) {
    const factory = this._componentFactoryResolver.resolveComponentFactory(type);
    return factory.create(injector);
  }
  /** Detaches a view from dirty checking again of ApplicationRef. */
  detach() {
    if (this._isDetached) {
      return;
    }
    this._isDetached = true;
    if (this._viewRef) {
      this._appRef.detachView(this._viewRef);
    }
    if (this._compRef) {
      this._appRef.detachView(this._compRef.hostView);
    }
    this._overlayContainer.removeOverlay(this);
    this._keydownEvents.complete();
  }
  /** Remove element of DOM */
  remove() {
    if (this._isRemoved) {
      return;
    }
    this._isRemoved = true;
    if (this._viewRef) {
      this._viewRef.destroy();
      this._overlayContainer._removeElement(this._el);
      this._el = null;
      this._viewRef = null;
    } else if (this._compRef) {
      this._compRef.destroy();
      this._overlayContainer._removeElement(this._el);
      this._el = null;
      this._compRef = null;
    } else if (this._el) {
      // remove if template is string
      this._overlayContainer._removeElement(this._el);
      this._el = null;
    }
    this.updateBackdrop(false);
    this._windowSRSub.unsubscribe();
  }
  /** Detach & remove */
  destroy() {
    this.detach();
    this.remove();
  }
}
class LyOverlay {
  constructor(_overlayContainer, _componentFactoryResolver, _appRef, _injector, _scrollDispatcher, _viewportRuler) {
    this._overlayContainer = _overlayContainer;
    this._componentFactoryResolver = _componentFactoryResolver;
    this._appRef = _appRef;
    this._injector = _injector;
    this._scrollDispatcher = _scrollDispatcher;
    this._viewportRuler = _viewportRuler;
  }
  create(templateOrComponent, context, config) {
    return new OverlayFactory(this._componentFactoryResolver, this._appRef, templateOrComponent, this._overlayContainer, context, this._injector, this._scrollDispatcher, this._viewportRuler, config);
  }
  static {
    this.ɵfac = function LyOverlay_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyOverlay)(i0.ɵɵinject(LyOverlayContainer), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i2.ScrollDispatcher), i0.ɵɵinject(i2.ViewportRuler));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LyOverlay,
      factory: LyOverlay.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyOverlay, [{
    type: Injectable
  }], () => [{
    type: LyOverlayContainer
  }, {
    type: i0.ComponentFactoryResolver
  }, {
    type: i0.ApplicationRef
  }, {
    type: i0.Injector
  }, {
    type: i2.ScrollDispatcher
  }, {
    type: i2.ViewportRuler
  }], null);
})();
class LyOverlayModule {
  static {
    this.ɵfac = function LyOverlayModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyOverlayModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LyOverlayModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [LyOverlay]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyOverlayModule, [{
    type: NgModule,
    args: [{
      declarations: [LyOverlayBackdrop],
      providers: [LyOverlay]
    }]
  }], null, null);
})();
const STYLES_BACKDROP_DARK = {
  backgroundColor: 'rgba(0,0,0,.32)'
};
const MUTATION_OBSERVER_INIT = {
  characterData: true,
  childList: true,
  subtree: true
};
class MutationObserverFactory {
  create(callback) {
    return typeof MutationObserver === 'undefined' ? null : new MutationObserver(callback);
  }
  static {
    this.ɵfac = function MutationObserverFactory_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MutationObserverFactory)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MutationObserverFactory,
      factory: MutationObserverFactory.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MutationObserverFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ElementObserver {
  constructor(_mutationObserverFactory) {
    this._mutationObserverFactory = _mutationObserverFactory;
    this._observedElements = new Map();
  }
  ngOnDestroy() {
    this._observedElements.forEach((_, element) => this.destroy(element));
  }
  observe(elementOrRef, fn, options) {
    const element = elementOrRef instanceof ElementRef ? elementOrRef.nativeElement : elementOrRef;
    if (!this._observedElements.has(element)) {
      const observer = this._mutationObserverFactory.create(fn);
      if (observer) {
        observer.observe(element, options || MUTATION_OBSERVER_INIT);
      }
      this._observedElements.set(element, observer);
    }
    return this._observedElements.get(element);
  }
  /**
   * Destroy Observer
   */
  destroy(elementOrRef) {
    const element = elementOrRef instanceof ElementRef ? elementOrRef.nativeElement : elementOrRef;
    if (this._observedElements.has(element)) {
      const observer = this._observedElements.get(element);
      if (observer) {
        this._observedElements.get(element).disconnect();
      }
      this._observedElements.delete(element);
    }
  }
  static {
    this.ɵfac = function ElementObserver_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ElementObserver)(i0.ɵɵinject(MutationObserverFactory));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ElementObserver,
      factory: ElementObserver.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ElementObserver, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: MutationObserverFactory
  }], null);
})();

/**
 * @deprecated use ViewportRuler instead
 * import { ViewportRuler } from '@angular/cdk/scrolling';
 */
class WinResize {
  constructor(document, ngZone) {
    this.document = document;
    if (Platform.isBrowser) {
      ngZone.runOutsideAngular(() => {
        this.resize$ = fromEvent(window, 'resize').pipe(auditTime(20), map(() => {
          return window.innerHeight || this.document.documentElement.clientHeight;
        }), share());
      });
    } else {
      this.resize$ = empty();
    }
  }
  static {
    this.ɵfac = function WinResize_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WinResize)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WinResize,
      factory: WinResize.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WinResize, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.NgZone
  }], null);
})();

/**
 * @deprecated use ScrollDispatcher instead
 * import { ScrollDispatcher } from '@angular/cdk/scrolling';
 */
class WinScroll {
  constructor(_document, ngZone) {
    this._document = _document;
    if (Platform.isBrowser) {
      ngZone.runOutsideAngular(() => {
        this.scroll$ = fromEvent(window.document, 'scroll').pipe(auditTime(20), map(() => {
          return window.scrollY || this._document.documentElement.scrollTop;
        }), share());
      });
    } else {
      this.scroll$ = empty();
    }
  }
  static {
    this.ɵfac = function WinScroll_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WinScroll)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WinScroll,
      factory: WinScroll.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WinScroll, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.NgZone
  }], null);
})();
class LyOverlayPosition {
  get x() {
    return this._x;
  }
  get y() {
    return this._y;
  }
  get xo() {
    return this._xo;
  }
  get yo() {
    return this._yo;
  }
  get width() {
    return this._width;
  }
  get height() {
    return this._height;
  }
  get triggerRect() {
    return this._triggerRect;
  }
  /** Returns x, but not rounded */
  get rawX() {
    return this._rawX;
  }
  /** Returns y, but not rounded */
  get rawY() {
    return this._rawY;
  }
  constructor(_theme, _viewportRuler, _trigger, _overlay) {
    this._theme = _theme;
    this._viewportRuler = _viewportRuler;
    this._trigger = _trigger;
    this._overlay = _overlay;
    this._xAxis = XPosition.after;
    this._yAxis = YPosition.below;
    this._flip = false;
    this._viewportOffset = 16;
    this._viewportHeight = 0;
    this._viewportWidth = 0;
    this._xOffset = 0;
    this._yOffset = 0;
  }
  build() {
    this._updateRects();
    this._setDefaultAnchor();
    this._updateDir();
    this._callTransformIfExists();
    this._calculateResponsive();
    this._calculateOrigin();
    this._round();
    return this;
  }
  _updateRects() {
    this._triggerRect = getMutableClientRect(this._trigger);
    this._overlayRect = getMutableClientRect(this._overlay);
    const {
      width,
      height
    } = this._viewportRuler.getViewportSize();
    this._viewportWidth = width;
    this._viewportHeight = height;
  }
  _setDefaultAnchor() {
    if (!this._xAnchor) {
      this._xAnchor = this._xAxis === XPosition.after ? XPosition.before : XPosition.after;
    }
    if (!this._yAnchor) {
      this._yAnchor = this._yAxis;
    }
  }
  _callTransformIfExists() {
    if (this._transform) {
      this._transform(this._triggerRect, this._overlayRect);
    }
  }
  _updateDir() {
    // If it is not number
    if (typeof this._xAnchor !== 'number') {
      this._anchorDir = this._theme.variables.getDirection(this._xAnchor);
    }
    this._axisDir = this._theme.variables.getDirection(this._xAxis);
  }
  _calculateResponsive() {
    this._calculate();
    if (this._flip && typeof this._xAnchor === 'string' && typeof this._yAnchor === 'string') {
      if (this._isOverflowX() && this._overlayRect.width < this._xa - this._viewportOffset) {
        this.setXAxis(flipPosition(this._xAxis)).setXAnchor(flipPosition(this._xAnchor));
        this._updateDir();
        this._calculate();
      }
      if (this._isOverflowY() && this._overlayRect.height < this._ya - this._viewportOffset) {
        this.setYAxis(flipPosition(this._yAxis)).setYAnchor(flipPosition(this._yAnchor));
        this._calculate();
      }
    }
    if (this._isOverflowX()) {
      if (this._overlayRect.width > this._viewportWidth - this._viewportOffset * 2) {
        this._x = this._viewportOffset;
        this._width = this._viewportWidth - this._viewportOffset * 2;
      } else if (this._isOverflowLeft()) {
        this._x = this._viewportOffset;
      } else if (this._isOverflowRight()) {
        this._x = this._viewportWidth - this._overlayRect.width - this._viewportOffset;
      }
    }
    if (this._isOverflowY()) {
      if (this._overlayRect.height > this._viewportHeight - this._viewportOffset * 2) {
        this._y = this._viewportOffset;
        this._height = this._viewportHeight - this._viewportOffset * 2;
      } else if (this._isOverflowTop()) {
        this._y = this._viewportOffset;
      } else if (this._isOverflowBottom()) {
        this._y = this._viewportHeight - this._overlayRect.height - this._viewportOffset;
      }
    }
  }
  /**
   * Return true if the menu overflows vertically
   */
  _isOverflowX() {
    return this._isOverflowLeft() // Left
    || this._isOverflowRight(); // Right
  }
  _isOverflowLeft() {
    return this.x < this._viewportOffset;
  }
  _isOverflowRight() {
    return this.x + this._overlayRect.width > this._viewportWidth - this._viewportOffset;
  }
  /** Return true the menu overflows horizontally */
  _isOverflowY() {
    return this._isOverflowTop() // Top
    || this._isOverflowBottom(); // Bottom
  }
  _isOverflowTop() {
    return this.y < this._viewportOffset;
  }
  _isOverflowBottom() {
    return this.y + this._overlayRect.height > this._viewportHeight - this._viewportOffset;
  }
  _calculate() {
    this._calculateAnchorPosition();
    this._calculateAxis();
  }
  _calculateAnchorPosition() {
    this._xa = this._triggerRect[this._anchorDir];
    if (this._yAnchor === YPosition.above) {
      this._ya = this._triggerRect.top;
    } else {
      this._ya = this._triggerRect.bottom;
    }
    // apply offset with support for rtl
    if (this._anchorDir === 'left') {
      this._xa += this._xOffset;
    } else {
      this._xa -= this._xOffset;
    }
    this._ya += this._yOffset;
  }
  _calculateAxis() {
    if (this._axisDir === 'left') {
      this._x = this._xa - this._overlayRect.width;
    } else {
      this._x = this._xa;
    }
    if (this._yAxis === YPosition.above) {
      this._y = this._ya - this._overlayRect.height;
    } else {
      this._y = this._ya;
    }
  }
  /** Calculate origin for overlay */
  _calculateOrigin() {
    const xAnchorCenter = this._xa - this._xOffset;
    const yAnchorCenter = this._ya - this._yOffset;
    const xOverlayCenter = this.x + this._overlayRect.width / 2;
    const yOverlayCenter = this.y + this._overlayRect.height / 2;
    const xo = xAnchorCenter - xOverlayCenter + this._overlayRect.width / 2;
    const yo = yAnchorCenter - yOverlayCenter + this._overlayRect.height / 2;
    this._xo = xo;
    this._yo = yo;
  }
  _round() {
    this._rawX = this.x;
    this._rawY = this.y;
    this._x = Math.round(this.x);
    this._y = Math.round(this.y);
  }
  // setTrigger(val: Element) {
  //   this._trigger = val;
  //   return this;
  // }
  // setOverlay(val: Element) {
  //   this._overlay = val;
  //   return this;
  // }
  setXAnchor(val) {
    this._xAnchor = val;
    return this;
  }
  setYAnchor(val) {
    this._yAnchor = val;
    return this;
  }
  setXAxis(val) {
    this._xAxis = val ?? XPosition.after;
    return this;
  }
  setYAxis(val) {
    this._yAxis = val ?? YPosition.below;
    return this;
  }
  setFlip(flip = true) {
    this._flip = flip;
    return this;
  }
  setXOffset(val) {
    this._xOffset = val;
    return this;
  }
  setYOffset(val) {
    this._yOffset = val;
    return this;
  }
  /**
   * Offset from the edge of the viewport to the center.
   * It is the minimum space that must be between the window and the overlay.
   */
  setViewportOffset(val) {
    this._viewportOffset = val;
    return this;
  }
  setTransform(transform) {
    this._transform = transform;
    return this;
  }
}
/** Gets a mutable version of an element's bounding `ClientRect`. */
function getMutableClientRect(element) {
  const clientRect = element.getBoundingClientRect();
  // We need to clone the `clientRect` here, because all the values on it are readonly
  // and we need to be able to update them. Also we can't use a spread here, because
  // the values on a `ClientRect` aren't own properties. See:
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect#Notes
  return {
    top: clientRect.top,
    right: clientRect.right,
    bottom: clientRect.bottom,
    left: clientRect.left,
    width: clientRect.width,
    height: clientRect.height
  };
}
function flipPosition(position) {
  switch (position) {
    case XPosition.after:
      return XPosition.before;
    case XPosition.before:
      return XPosition.after;
    case XPosition.left:
      return XPosition.right;
    case XPosition.right:
      return XPosition.left;
    case YPosition.above:
      return YPosition.below;
    case YPosition.below:
      return YPosition.above;
  }
}
var AlignAlias;
(function (AlignAlias) {
  AlignAlias["rowReverse"] = "row-reverse";
  AlignAlias["columnReverse"] = "column-reverse";
  AlignAlias["wrapReverse"] = "wrap-reverse";
  AlignAlias["start"] = "flex-start";
  AlignAlias["end"] = "flex-end";
  AlignAlias["between"] = "space-between";
  AlignAlias["around"] = "space-around";
  AlignAlias["evenly"] = "space-evenly";
})(AlignAlias || (AlignAlias = {}));
function same(o) {
  return o;
}
class LySelectionModel {
  /** Selected values. */
  get selected() {
    if (!this._selected) {
      this._selected = Array.from(this._selectionMap.values());
    }
    return this._selected;
  }
  constructor(opts) {
    this._selectionMap = new Map();
    this._getKeyFn = same;
    if (!opts) {
      return;
    }
    const {
      multiple,
      getKey
    } = opts;
    if (getKey) {
      this._getKeyFn = getKey;
    }
    if (multiple === true) {
      this._multiple = true;
      const {
        selecteds
      } = opts;
      if (Array.isArray(selecteds) && selecteds.length) {
        this.select(...selecteds);
      }
    } else {
      const {
        selecteds
      } = opts;
      if (selecteds) {
        this._markSelected(selecteds);
      }
    }
  }
  /**
   * Toggles a value between selected and deselected.
   */
  toggle(value) {
    this.isSelected(value) ? this.deselect(value) : this.select(value);
  }
  /**
   * Selects one or several values.
   */
  select(...values) {
    values.forEach(value => this._markSelected(value));
    this._clearSelectedValues();
  }
  /**
   * Deselects a value or an array of values.
   */
  deselect(...values) {
    values.forEach(value => this._unmarkSelected(value));
    this._clearSelectedValues();
  }
  /**
   * Determines whether a value is selected.
   */
  isSelected(value) {
    const key = this._getKeyFn(value);
    return this._selectionMap.has(key);
  }
  /**
   * Determines whether the model does not have a value.
   */
  isEmpty() {
    return this._selectionMap.size === 0;
  }
  /**
   * Determines whether the model has a value.
   */
  hasValue() {
    return this._selectionMap.size !== 0;
  }
  /**
   * Gets whether multiple values can be selected.
   */
  isMultipleSelection() {
    return this._multiple;
  }
  /**
   * Clears all of the selected values.
   */
  clear() {
    this._unmarkAll();
    this._clearSelectedValues();
  }
  /** Selects a value. */
  _markSelected(value) {
    if (!this.isSelected(value)) {
      if (!this._multiple) {
        this._unmarkAll();
      }
      const key = this._getKeyFn(value);
      this._selectionMap.set(key, value);
    }
  }
  /** Deselects a value. */
  _unmarkSelected(value) {
    if (this.isSelected(value)) {
      const key = this._getKeyFn(value);
      this._selectionMap.delete(key);
    }
  }
  /** Clears out the selected values. */
  _unmarkAll() {
    if (!this.isEmpty()) {
      this._selectionMap.clear();
    }
  }
  /** Clear the selected values so they can be re-cached. */
  _clearSelectedValues() {
    this._selected = null;
  }
}
function getLyThemeVariableUndefinedError(variable) {
  return Error(`Variable '${variable}' undefined in Theme.`);
}
function getLyThemeVariableOptionUndefinedError(comp, variable) {
  return Error(`${comp}: variable ${variable} is undefined in Theme.`);
}
function getLyThemeStyleUndefinedError(comp, input, val) {
  return Error(`${comp}: no styles defined in the theme have been found for \`@Input() ${input}\`,` + ` the value given is \`${val}\`.`);
}
const STYLES = (theme, ref) => {
  const __ = ref.selectorsOf(STYLES);
  return {
    root: _className => `${_className}{width:1em;height:1em;display:inline-block;position:relative;font-size:24px;}`,
    line: _className => `${_className}{top:calc(0.5em - 1px);position:absolute;width:${1 / 3}em;height:2px;background-color:currentColor;display:inline-block;transition:all ${theme.animations.durations.entering}ms ${theme.animations.curves.standard};}${_className}:first-of-type{left:0.25em;-webkit-transform:rotate(45deg);transform:rotate(45deg);}${_className}:last-of-type{right:0.25em;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);}`,
    up: () => _className => `${_className} ${__.line}:first-of-type{-webkit-transform:rotate(-45deg);transform:rotate(-45deg);}${_className} ${__.line}:last-of-type{-webkit-transform:rotate(45deg);transform:rotate(45deg);}`
  };
};
class LyExpansionIcon {
  set color(val) {
    this._colorClass = this._theme.addStyle('LyExpansionIcon.color', theme => ({
      '{line}': {
        backgroundColor: theme.colorOf(val)
      }
    }), this._el.nativeElement, this._colorClass, null, STYLES);
  }
  get color() {
    return this._color;
  }
  set up(val) {
    const newVal = coerceBooleanProperty(val);
    if (newVal !== this.up) {
      this._up = newVal;
      if (newVal) {
        this._renderer.addClass(this._el.nativeElement, this.classes.up);
      } else {
        this._renderer.removeClass(this._el.nativeElement, this.classes.up);
      }
    }
  }
  get up() {
    return this._up;
  }
  constructor(sRenderer, _theme, _renderer, _el) {
    this.sRenderer = sRenderer;
    this._theme = _theme;
    this._renderer = _renderer;
    this._el = _el;
    this.classes = this.sRenderer.renderSheet(STYLES, true);
    this._up = false;
  }
  toggle() {
    this.up = !this.up;
  }
  static {
    this.ɵfac = function LyExpansionIcon_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyExpansionIcon)(i0.ɵɵdirectiveInject(StyleRenderer), i0.ɵɵdirectiveInject(LyTheme2), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LyExpansionIcon,
      selectors: [["ly-expansion-icon"]],
      inputs: {
        color: "color",
        up: "up"
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([StyleRenderer])],
      decls: 2,
      vars: 2,
      consts: [[3, "className"]],
      template: function LyExpansionIcon_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "span", 0)(1, "span", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("className", ctx.classes.line);
          i0.ɵɵadvance();
          i0.ɵɵproperty("className", ctx.classes.line);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyExpansionIcon, [{
    type: Component,
    args: [{
      selector: 'ly-expansion-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [StyleRenderer],
      template: "<span [className]=\"classes.line\"></span>\n<span [className]=\"classes.line\"></span>"
    }]
  }], () => [{
    type: StyleRenderer
  }, {
    type: LyTheme2
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    color: [{
      type: Input
    }],
    up: [{
      type: Input
    }]
  });
})();
class LyExpansionIconModule {
  static {
    this.ɵfac = function LyExpansionIconModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LyExpansionIconModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LyExpansionIconModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LyExpansionIconModule, [{
    type: NgModule,
    args: [{
      declarations: [LyExpansionIcon],
      exports: [LyExpansionIcon]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AUI_LAST_UPDATE, AUI_VERSION, AlignAlias, CoreTheme, Dir, DirAlias, DirPosition, ElementObserver, FocusStatus, IS_CORE_THEME, LY_CLASS_NAME_PREFIX, LY_COMMON_STYLES, LY_COMMON_STYLES_DEPRECATED, LY_ENABLE_SELECTORS_FN, LY_HAMMER_OPTIONS, LY_THEME, LY_THEME_GLOBAL_VARIABLES, LY_THEME_NAME, LyCommonModule, LyCoreStyles, LyExpansionIcon, LyExpansionIconModule, LyFocusState, LyHammerGestureConfig, LyHostClass, LyOverlay, LyOverlayBackdrop, LyOverlayConfig, LyOverlayContainer, LyOverlayModule, LyOverlayPosition, LyOverlayRef, LyPaper, LyPaperBase, LyPaperMixinBase, LyRippleService, LySelectionModel, LyStyle, LyStyleDeprecated, LyStyleDeprecatedModule, LyStyleUtils, LyTheme2, LyThemeModule, LyWithClass, LylParse, MutationObserverFactory, NgTranscludeDirective, NgTranscludeModule, OverlayFactory, Platform, Positioning, Ripple, STYLES_BACKDROP_DARK, STYLES_BACKDROP_ROOT, Shadows, StringIdGenerator, Style, Style2, StyleCollection, StyleRenderer, StylesInDocument, THEME_VARIABLES, TypeStyle, Undefined, UndefinedValue, WinResize, WinScroll, XPosition, YPosition, _STYLE_MAP, _renderStyle, capitalizeFirstLetter, ck, converterToCssKeyAndStyle, createOverlayInjector, createStyle, defaultEntry, dot, eachMedia, getContrastYIQ, getLyThemeStyleUndefinedError, getLyThemeVariableOptionUndefinedError, getLyThemeVariableUndefinedError, getNativeElement, getThemeNameForSelectors, invertPlacement, keyframesUniqueId, lyl, memoize, mergeDeep, mergeThemes, mixinBg, mixinColor, mixinDisableRipple, mixinDisabled, mixinElevation, mixinOutlined, mixinRaised, mixinShadowColor, mixinStyleUpdater, mixinTabIndex, parseMediaQueriesFromString, parseMediaQueryFromString, resolveSelectors, scrollTo, scrollWithAnimation, shadowBuilder, st2c, supportsPassiveEventListeners, toBoolean, toNumber, untilComponentDestroyed, withMediaInline };
