import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cca-page-state',
  imports: [MatButtonModule, NgClass],
  templateUrl: './page-state.component.html',
  styleUrls: ['./page-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageStateComponent {
  @Input() image = 'assets/images/order-empty-state.svg';
  @Input() text = '';
  @Input() subText = '';
  @Input() buttonText = '';
  @Input() smallFormat = false;
  @Output() buttonClicked = new EventEmitter<void>();
}
