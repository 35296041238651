import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective } from '@ngrx/component';

type ConfirmationPageTranslationKeys =
  | 'title'
  | 'referenceLabel'
  | 'paragraph'
  | 'primaryButtonLabel'
  | 'secondaryButtonLabel'
  | 'subtitle';

type ConfirmationPageTranslations = Record<
  ConfirmationPageTranslationKeys,
  string
>;

@Component({
  selector: 'cca-confirmation-page',
  imports: [LetDirective, MatButtonModule],
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationPageComponent {
  @Input() image = 'assets/images/booking/circle-checkmark.svg';
  @Input() referenceNumber?: string;
  @Input() translations: ConfirmationPageTranslations | undefined = {
    title: 'Your request is received',
    referenceLabel: 'Request number:',
    paragraph: '',
    primaryButtonLabel: 'To green',
    secondaryButtonLabel: 'To white',
    subtitle: '',
  };

  @Output() primaryButtonClick = new EventEmitter<void>();
  @Output() secondaryButtonClick = new EventEmitter<void>();
}
