import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  EvaluateQuotationRequest,
  SetQuotationPriceRequest,
  UpdateTransportIterationDatesRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class QuotationService extends BaseApiService {
  constructor() {
    super(`request.v1.quotation`);
  }

  setPrice(request: SetQuotationPriceRequest) {
    return this.http.post(`${this.url}set-price`, request);
  }

  evaluate(quotationRequest: EvaluateQuotationRequest) {
    return this.http.post<string[]>(`${this.url}evaluate`, quotationRequest);
  }

  updateRequestIterationTimes(update: UpdateTransportIterationDatesRequest) {
    return this.http.post<void>(`${this.url}update-iteration-dates`, update);
  }
}
