import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject, makeEnvironmentProviders } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { NoAccessContextToken } from '../context';
import { LoggedInSignalToken } from '../logged-in-signal';

@Injectable()
export class NoAccessInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private loggedIn = inject(LoggedInSignalToken);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const noAccessContext = req.context.get(NoAccessContextToken);
    if (!noAccessContext) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error &&
          (error as unknown as HttpErrorResponse).status === 403 &&
          this.loggedIn()
        ) {
          this.router.navigate(['page-not-permitted']);
        }

        // rethrow the error
        return throwError(() => error);
      }),
    );
  }
}

export function provideNoAccessInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoAccessInterceptor,
      multi: true,
    },
  ]);
}
