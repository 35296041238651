import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  CreateLegalDocumentVersionRequest,
  LegalDocumentGroupingsViewModel,
  LegalDocumentVersionDetailViewModel,
  LegalDocumentVersionNumberViewModel,
  LegalDocumentViewModel,
  PublishLegalDocumentVersionRequest,
  UpdateLegalDocumentVersionRequest,
} from '../model';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class EditorService extends BaseApiService {
  constructor() {
    super('legal.v1.editor');
  }

  getGroupTypes() {
    return this.http.get<LegalDocumentGroupingsViewModel[]>(
      `${this.url}grouping-types`,
    );
  }

  paginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LegalDocumentViewModel>>(
      `${this.url}document-paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  getDocument(documentId: id) {
    return this.http.get<LegalDocumentViewModel>(
      `${this.url}document-read/${documentId}`,
    );
  }

  getAllVersions(documentId: id) {
    return this.http.get<LegalDocumentVersionNumberViewModel[]>(
      `${this.url}document-read-versions/${documentId}`,
    );
  }

  createDocument(request: CreateLegalDocumentVersionRequest) {
    return this.http.put(`${this.url}version-create`, request);
  }

  updateDocument(request: UpdateLegalDocumentVersionRequest) {
    return this.http.post(`${this.url}version-update`, request);
  }

  publishDocument(request: PublishLegalDocumentVersionRequest) {
    return this.http.post(`${this.url}version-publish`, request);
  }

  readDocument(versionId: id) {
    return this.http.get<LegalDocumentVersionDetailViewModel>(
      `${this.url}version-detail/${versionId}`,
    );
  }

  deleteDocument(versionId: id) {
    return this.http.delete<LegalDocumentVersionDetailViewModel>(
      `${this.url}version-delete/${versionId}`,
    );
  }
}
