/**
 * Task Management API
 * <p>The Task Management API is used to manage task board.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincargo.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = Web  2 = CarrierTMSMobile  3 = TMSAll  4 = WMSMobile  5 = WMSAll  6 = MobileAny  7 = All
 */
export enum RoleEnvironmentPermissionType {
  None = 0,
  Web = 1,
  CarrierTMSMobile = 2,
  TMSAll = 3,
  WMSMobile = 4,
  WMSAll = 5,
  MobileAny = 6,
  All = 7,
}
