@if (items) {
  <article class="flex h-8 max-w-fit">
    @if (items.length === 1) {
      <cca-avatar
        [url]="items[0].avatarURL"
        [initials]="getInitials(items[0])"
        [size]="size"
      ></cca-avatar>

      <span class="ml-1 self-center">{{ getFullName(items[0]) }}</span>
    }

    @if (items.length > 1) {
      @for (item of items.slice(0, visibleLength); track item.id) {
        <cca-avatar
          [url]="item.avatarURL"
          [initials]="getInitials(item)"
          [size]="size"
        ></cca-avatar>
      }

      @if (omittedItems.length) {
        <div
          [ngxTippy]="taskOwnersDialog"
          tippyClassName="rounded-lg"
          [tippyProps]="{
            placement: 'bottom',
            arrow: true,
            theme: 'dark',
            interactive: true,
            appendTo: body,
            trigger: 'mouseenter click',
          }"
          class="-ml-3 box-content grid h-8 w-8 flex-shrink-0 place-items-center rounded-full border-2 border-neutral-invert info-surface-lightest info-text"
        >
          <p class="rounded text-xs font-medium">+{{ omittedItems.length }}</p>
        </div>
      }
    }
  </article>
}

<ng-template #taskOwnersDialog let-name>
  <ul class="h-52 overflow-scroll p-3">
    @for (item of omittedItems; track item.id; let last = $last) {
      <li class="flex flex-nowrap gap-1" [class.mb-1]="!last">
        <cca-avatar
          [url]="item.avatarURL"
          [initials]="getInitials(item)"
          [mode]="Mode.Dark"
          [size]="8"
        >
        </cca-avatar>
        <span class="ml-1 self-center">{{ getFullName(item) }}</span>
      </li>
    }
  </ul>
</ng-template>
