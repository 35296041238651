import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class TransportRequestService extends BaseApiService {
  constructor() {
    super(`request.v1.transport`);
  }

  delete(requestId: id) {
    return this.http.delete<boolean>(`${this.url}delete/${requestId}`);
  }
}
