/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = Perishable  2 = Food  3 = FoodAndPerishable
 */
export enum CargoProductType {
  None = 0,
  Perishable = 1,
  Food = 2,
  FoodAndPerishable = 3,
}
