import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CtrlChainIcons } from '@cca-common/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'cca-icon-label-group',
  imports: [IconComponent],
  templateUrl: './icon-label-group.component.html',
  styleUrls: ['./icon-label-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLabelGroupComponent {
  @Input() firstIcon!: CtrlChainIcons;
  @Input() firstLabel!: string;
  @Input() secondIcon!: CtrlChainIcons;
  @Input() secondLabel!: string;
}
