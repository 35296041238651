import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CdkDatePipe } from '@cca-common/cdk';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import {
  PlatformEnvironmentToken,
  PlatformEnvironmentConfiguration,
} from '@cca-environment';
import { AuditLogItemViewModel } from '@cca-infra/common';
// TODO(jelle): moved Rich text to ui possibly

import { RichTextViewerComponent } from '../rich-text-viewer';

export type EventLogColumns =
  | 'objectName'
  | 'oldValue'
  | 'newValue'
  | 'translatedIdentifier'
  | 'changedBy'
  | 'auditTimeStamp';

@Component({
  imports: [
    TranslocoModule,
    MatTableModule,
    CdkDatePipe,
    RichTextViewerComponent,
  ],
  selector: 'cca-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('eventLog')],
})
export class EventLogComponent {
  environment = inject<PlatformEnvironmentConfiguration>(
    PlatformEnvironmentToken,
  );

  @Input() dataSource: MatTableDataSource<AuditLogItemViewModel>;
  @Input() displayedColumns!: EventLogColumns[];

  constructor() {
    this.dataSource = new MatTableDataSource<AuditLogItemViewModel>();
  }

  containsHTML(input: string): boolean {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(input);
  }
}
