/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = New  2 = Tendered  3 = InternallyRefused  4 = Refused  5 = ReadyToOffer  6 = Offered  7 = Accepted  8 = Rejected  9 = Expired
 */
export enum LaneRowStatus {
  Undefined = 0,
  New = 1,
  Tendered = 2,
  InternallyRefused = 3,
  Refused = 4,
  ReadyToOffer = 5,
  Offered = 6,
  Accepted = 7,
  Rejected = 8,
  Expired = 9,
}
