/**
 * Location API
 * <p>The Location API is used to manage Lanes, Addresses and other locations/p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Nothing  1 = Tolls  2 = Highways  4 = Tunnels  8 = Ferries
 */
export enum AvoidType {
  Nothing = 0,
  Tolls = 1,
  Highways = 2,
  Tunnels = 4,
  Ferries = 8,
}
