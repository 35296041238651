import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CtrlChainIcons, StatusBadgeFlavor } from '@cca-common/core';
import { Snackbar } from '@cca-common/snackbar';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { UiStatusBadgeComponent } from '../badges';
import {
  CroppedImageObject,
  DialogImageCropperComponent,
} from '../dialog-image-cropper';
import { IconComponent } from '../icon';

@Component({
  imports: [
    IconComponent,
    MatDialogModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgClass,
    UiStatusBadgeComponent,
  ],
  selector: 'cca-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionHeaderComponent implements OnChanges {
  dialog = inject(MatDialog);
  cdr = inject(ChangeDetectorRef);

  @Input() icon: CtrlChainIcons = 'box';
  @Input() title = 'title';
  @Input() description = '';
  @Input() avatarImage: string | null | undefined;
  @Input() avatarDimensions: number[] = [200, 200];
  @Input() avatarRoundShape = true;
  @Input() editImage = false;
  @Input() editOnImageClick = false;
  @Input() editButtonLabel = 'Change';
  @Input() status: string | null = null;
  @Input() statusFlavor: StatusBadgeFlavor | null = null;
  @Output() returnAvatarImage = new EventEmitter<CroppedImageObject>();

  croppedImage: string | undefined;
  shownImage: string | null | undefined;
  maxAvatarSize = 2101546;
  private snackbar = inject(Snackbar);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.avatarImage) {
      this.shownImage = this.avatarImage;
    }
  }

  uploadAvatar() {
    if (!this.editImage) return;

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', this.fileChangeEvent.bind(this));
    fileInput.click();
  }

  private fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target.files) {
      return;
    }
    const file = target.files[0];
    const url = URL.createObjectURL(file);
    const img = new Image();
    img.src = url;
    let imageData = { url: url, name: file.name };
    setTimeout(() => {
      const height = img.height;
      const width = img.width;

      if (height < 400 || width < 400) {
        const lowVal = Math.min(width, height);
        const multiplierNumber = 400 / lowVal;
        const elem = document.createElement('canvas');
        elem.width = width * multiplierNumber;
        elem.height = height * multiplierNumber;
        const ctx = elem.getContext('2d');
        ctx?.drawImage(
          img,
          0,
          0,
          width * multiplierNumber,
          height * multiplierNumber,
        );
        const modifiedUrl = ctx?.canvas.toDataURL();
        const modifiedImg = new Image();
        modifiedImg.src = modifiedUrl as string;
        imageData = { url: modifiedImg.src, name: file.name };
      }
      if (file.size > this.maxAvatarSize && imageData.url) {
        this.snackbar.open(t('image.avatar.sizeIndication'));
        return;
      } else {
        this.dialog
          .open(DialogImageCropperComponent, {
            data: {
              cropRoundShape: this.avatarRoundShape,
              cropDimensions: this.avatarDimensions,
              fileEvent: event,
              imageData: imageData,
            },
          })
          .afterClosed()
          .subscribe((imageData) => {
            if (imageData) {
              this.croppedImage = imageData.img;
              this.shownImage = this.croppedImage;
              this.cdr.detectChanges();
              this.returnAvatarImage.emit({
                imageFile: imageData.imgFile,
                croppedImage: this.croppedImage,
              });
            }
          });
      }
    }, 100);
  }
}
