import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OverviewDetailComponent } from '../overview-detail/overview-detail.component';
import { CtrlChainIcons } from '@cca-common/core';
import { IconLabelGroupComponent } from '../../icon-label-group';

@Component({
  selector: 'cca-overview-shipper',
  templateUrl: './overview-shipper.component.html',
  styleUrls: ['./overview-shipper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OverviewDetailComponent, IconLabelGroupComponent],
})
export class OverviewShipperComponent {
  @Input() shipperTitle!: string;
  @Input() enterpriseIcon!: CtrlChainIcons;
  @Input() enterpriseLabel!: string;
  @Input() groupIcon!: CtrlChainIcons;
  @Input() groupLabel!: string;
  @Input() userTitle!: string;
  @Input() userName!: string;
}
