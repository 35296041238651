/**
 * Legal Comliance API
 * <p>The Legal Compliance API is used to manage Contractual Agreements.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincargo.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Legal Document Status  0 = Undefined  1 = Empty  2 = Draft  3 = Published
 */
export enum LegalDocumentStatus {
  Undefined = 0,
  Empty = 1,
  Draft = 2,
  Published = 3,
}
