import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  HostBinding,
} from '@angular/core';
import {
  AnimationLoader,
  AnimationOptions,
  LottieComponent,
  provideLottieOptions,
} from 'ngx-lottie';

@Component({
  imports: [LottieComponent],
  providers: [
    provideLottieOptions({
      // when deployed lottie-web does not work when the player is loaded without `then((x) => x)`
      player: () => import('lottie-web').then((x) => x),
    }),
    AnimationLoader,
  ],
  selector: 'cca-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
  @Input() set inline(value: boolean) {
    this.inlineClass = value;
  }

  @HostBinding('class.inline-grid') inlineClass = true;

  options: AnimationOptions = {
    // note, this file might be cached by browsers if we ever change this we would need a new name such that the browser thinks it is different file
    path: 'assets/icons/logo.json',
  };
}
