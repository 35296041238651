/**
 * Finance API
 * <p>The Finance API is used to request finance data from the platform.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = BookingFee  2 = FtlFixedPrice  3 = CourierFixedPrice  4 = DeliveryBeforeFive  5 = CO2Contribution  6 = FTLFuelSurcharge  7 = ManageTransFee  8 = ManagedTransFixedPriceTransport  9 = ExtraStopCourier  10 = ExtraStopFTL  11 = CourierFuelSurcharge  12 = FCLFixedPrice
 */
export enum ArticleCodeType {
  Undefined = 0,
  BookingFee = 1,
  FtlFixedPrice = 2,
  CourierFixedPrice = 3,
  DeliveryBeforeFive = 4,
  CO2Contribution = 5,
  FTLFuelSurcharge = 6,
  ManageTransFee = 7,
  ManagedTransFixedPriceTransport = 8,
  ExtraStopCourier = 9,
  ExtraStopFTL = 10,
  CourierFuelSurcharge = 11,
  FCLFixedPrice = 12,
}
