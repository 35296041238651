/**
 * Finance API
 * <p>The Finance API is used to request finance data from the platform.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Credit  2 = Debit
 */
export enum InvoiceType {
  Undefined = 0,
  Credit = 1,
  Debit = 2,
}
