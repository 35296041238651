import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  ArticleType,
  ArticleViewModel,
  DeleteAllRelatedGroupArticleRatesRequest,
  DeleteRelatedGroupArticleRateRequest,
  LogisticServiceProviderType,
  OrderArticleViewModel,
  ShipmentType,
  ReadRelatedGroupArticlesRequest,
  UpdateRelatedGroupArticleRateRequest,
  ProductType,
} from '../model';
import { id } from '@cca-infra/common';
import { GroupRate } from './usergrouprate.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends BaseApiService {
  constructor() {
    super(`finance.v1.article`);
  }

  getAll(
    userGroupId: id,
    shipperUserGroupId: id,
    orderType: LogisticServiceProviderType,
    shipmentType: ShipmentType,
    productType: ProductType,
    relatedGroupId: id | null = null,
  ) {
    return this.http.post<ArticleViewModel[]>(`${this.url}get-all`, {
      userGroupId,
      shipperUserGroupId,
      orderLogisticServiceProviderType: orderType,
      shipmentType,
      productType,
      relatedGroupId,
    });
  }

  updateArticle(article: OrderArticleViewModel) {
    return this.http.put<OrderArticleViewModel>(`${this.url}order`, article);
  }

  addArticles(
    orderId: id,
    articleIds: id[],
    userGroupId: id,
    type: ArticleType,
  ) {
    return this.http.post<OrderArticleViewModel[]>(`${this.url}order`, {
      articleIds,
      orderId,
      userGroupId,
      articleType: type,
    });
  }

  addCTMSArticles(
    carrierOrderId: id,
    articleIds: id[] | null,
    userGroupId: id,
    type: ArticleType,
    quantity: number | null,
    rate: number | null,
  ) {
    return this.http.post<OrderArticleViewModel[]>(`${this.url}carrier-order`, {
      articleIds,
      carrierOrderId,
      userGroupId,
      articleType: type,
      quantity,
      rate,
    });
  }

  deleteArticle(article: OrderArticleViewModel) {
    return this.http.delete<OrderArticleViewModel>(
      `${this.url}order/${article.orderArticleId}`,
    );
  }

  getCTMSGroupRates(body: ReadRelatedGroupArticlesRequest) {
    return this.http.post<GroupRate[]>(`${this.url}related-group/rates`, body);
  }

  updateCTMSGroupRate(body: UpdateRelatedGroupArticleRateRequest) {
    return this.http.put(`${this.url}related-group/rate`, body);
  }

  resetCTMSGroupRate(body: DeleteRelatedGroupArticleRateRequest) {
    return this.http.post(`${this.url}related-group/reset`, body);
  }

  resetAllCTMSGroupRates(body: DeleteAllRelatedGroupArticleRatesRequest) {
    return this.http.post(`${this.url}related-group/reset/all`, body);
  }
}
