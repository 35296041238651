import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { CtrlChainIcons } from '@cca-common/core';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-detail-item',
  imports: [NgClass, IconComponent],
  templateUrl: './detail-item.component.html',
  styleUrls: ['./detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailItemComponent {
  @Input() icon: CtrlChainIcons = '';
  @Input() label?: string;
  @Input() value?: number | string;
  @Input() symbol?: string;
  @Input() smallSize = false;
}
