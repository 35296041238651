<div class="page-container w-full" *transloco="let t; read: 'group'">
  <div class="flex items-center justify-between">
    <cca-section-header
      icon="cube"
      class="!mb-0"
      [title]="userGroup()?.userGroupName ?? ''"
      [description]="userGroup()?.verticalMainCategoryName ?? ''"
      [avatarImage]="groupAvatar()"
      [avatarRoundShape]="false"
    ></cca-section-header>

    @if (canUpdateGroup() && !isInternalGroup()) {
      <button mat-stroked-button color="primary" (click)="editClick()">
        {{ t('edit') }}
      </button>
    }
  </div>
</div>
