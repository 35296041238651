/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Metric  1 = ImperialUS
 */
export enum MeasurementSystemType {
  Metric = 0,
  ImperialUS = 1,
}
