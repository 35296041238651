/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Requested  1 = QuoteProcessing  2 = QuoteAccepted  3 = QuoteRejected  4 = QuoteRefused  5 = QuoteRequested  10 = OrdersGenerated  11 = QuoteClosed
 */
export enum RequestStatus {
  Requested = 0,
  QuoteProcessing = 1,
  QuoteAccepted = 2,
  QuoteRejected = 3,
  QuoteRefused = 4,
  QuoteRequested = 5,
  OrdersGenerated = 10,
  QuoteClosed = 11,
}
