import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CdkDatePipe, DatePipeInput } from '@cca-common/cdk';
import { Locale } from '@jsverse/transloco-locale';
import { Zone } from 'luxon';

@Component({
  imports: [CdkDatePipe],
  selector: 'cca-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDateCellComponent {
  @Input() format = 'shortDate';
  @Input() date!: DatePipeInput;

  @Input() locale: Locale | null | undefined = undefined;
  @Input() timezone: string | Zone | null | undefined = undefined;
}
