import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { NgClass } from '@angular/common';

import { id } from '@cca-infra/common';
import {
  ActionHeaderComponent,
  Flavor,
  OrderActionLike,
} from './action-header';

@Component({
  selector: 'cca-user-card-radio',
  imports: [NgClass, ActionHeaderComponent, MatRadioModule],
  templateUrl: './user-card-radio.component.html',
  styleUrls: ['./user-card-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardRadioComponent implements OnInit {
  Flavor = Flavor;
  @Input() label?: string;
  @Input() description?: string;
  @Input() isSelected = false;
  @Input() value?: id;

  @Output() radioChanged = new EventEmitter<id>();

  @ViewChild(MatRadioButton) radioBtn: MatRadioButton | undefined;
  action: OrderActionLike = {} as OrderActionLike;

  ngOnInit() {
    this.action = {
      label: this.label ?? '',
      shortDescription: this.description ?? '',
      iterationNumber: undefined,
      reDoActionLabel: undefined,
    };
  }
}
