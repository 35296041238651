/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NotSet  1 = PriceOffered  2 = Accepted  3 = Rejected  4 = Refused  5 = Expired
 */
export enum RequestIterationStatus {
  NotSet = 0,
  PriceOffered = 1,
  Accepted = 2,
  Rejected = 3,
  Refused = 4,
  Expired = 5,
}
