/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = At  2 = Between  3 = Before  4 = After
 */
export enum TimeIndicationType {
  Undefined = 0,
  At = 1,
  Between = 2,
  Before = 3,
  After = 4,
}
