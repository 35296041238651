import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StatusBadgeFlavor } from '@cca-common/core';

@Component({
  imports: [NgClass],
  selector: 'cca-numerical-badge',
  styleUrls: ['./numerical-badge.component.scss'],
  templateUrl: './numerical-badge.component.html',
})
export class UiNumericalBadgeComponent {
  @Input() flavor: StatusBadgeFlavor = 'primary';
  @Input() number: number | string = '';
  @Input() smallBadge = false;
}
