/**
 * Task Management API
 * <p>The Task Management API is used to manage task board.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincargo.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * TaskType  0 = Undefined  1 = New  2 = Active  3 = Closed  4 = Deleted
 */
export enum TaskState {
  Undefined = 0,
  New = 1,
  Active = 2,
  Closed = 3,
  Deleted = 4,
}
