/**
 * Location API
 * <p>The Location API is used to manage Lanes, Addresses and other locations/p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   1 = Fastest  2 = Shortest
 */
export enum RouteType {
  Fastest = 1,
  Shortest = 2,
}
