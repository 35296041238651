import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { DateTime } from 'luxon';
import { CdkDatePipe } from '@cca-common/cdk';
import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { TimeIndicationType } from '@cca-infra/order-management/v2';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { CCADateTime } from '@cca-infra/common';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-time-window',
  imports: [CdkDatePipe, IconComponent, TranslocoModule, NgxTippyModule],
  templateUrl: './time-window.component.html',
  styleUrls: ['./time-window.component.scss'],
  providers: [provideTranslocoScope('timeWindow')],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeWindowComponent {
  private _timeWindowStart: CCADateTime | DateTime | null | undefined;
  private _timeWindowEnd: CCADateTime | DateTime | null | undefined;
  private transloco = inject(TranslocoService);
  TimeIndicationType = TimeIndicationType;

  @Input() timeslotPreAgreed?: boolean;
  @Input() timeslotRequired?: boolean;
  @Input() timeOptionType?: TimeIndicationType;
  @Input() label = '';
  @Input() isRange = false;
  @Input() timezone = 'system';
  @Input() showClockIcon = true;
  @Input() showTimeOneLine = false;
  @Input() set timeWindowStart(
    timeWindowStart: CCADateTime | DateTime | number | null | undefined,
  ) {
    if (typeof timeWindowStart === 'number') {
      this._timeWindowStart = DateTime.fromMillis(timeWindowStart, {
        zone: this.timezone,
      });
    } else {
      this._timeWindowStart = timeWindowStart;
    }
  }
  get timeWindowStart() {
    return this._timeWindowStart;
  }

  @Input() set timeWindowEnd(
    timeWindowEnd: CCADateTime | DateTime | number | null | undefined,
  ) {
    if (typeof timeWindowEnd === 'number') {
      this._timeWindowEnd = DateTime.fromMillis(timeWindowEnd, {
        zone: this.timezone,
      });
    } else {
      this._timeWindowEnd = timeWindowEnd;
    }
  }
  get timeWindowEnd() {
    return this._timeWindowEnd;
  }

  protected timeOptionTranslation(timeOptionType: TimeIndicationType) {
    switch (timeOptionType) {
      case TimeIndicationType.Between:
        return this.transloco.translate(t('timeWindow.timeOption.between'));
      case TimeIndicationType.At:
        return this.transloco.translate(t('timeWindow.timeOption.at'));
      case TimeIndicationType.After:
        return this.transloco.translate(t('timeWindow.timeOption.after'));
      case TimeIndicationType.Before:
        return this.transloco.translate(t('timeWindow.timeOption.before'));
      case TimeIndicationType.Undefined:
      default:
        return this.transloco.translate(t('timeWindow.timeOption.unknown'));
    }
  }

  body = document.body;
}
