/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NoRepeat  1 = Weekly  2 = Monthly
 */
export enum ShipmentRepeatType {
  NoRepeat = 0,
  Weekly = 1,
  Monthly = 2,
}
