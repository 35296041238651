/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = A_A  2 = A_B  3 = A_C  4 = A_D  5 = A_AB  6 = A_BC  7 = A_BCD
 */
export enum TransitTimeType {
  Undefined = 0,
  A_A = 1,
  A_B = 2,
  A_C = 3,
  A_D = 4,
  A_AB = 5,
  A_BC = 6,
  A_BCD = 7,
}
