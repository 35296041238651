import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { PriceViewModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PriceService extends BaseApiService {
  constructor() {
    super(`finance.v1.price`);
  }

  calculate(data: unknown) {
    return this.http.post<PriceViewModel>(`${this.url}calculate`, data);
  }

  calculateBuyRate(data: unknown) {
    return this.http.post<PriceViewModel>(`${this.url}calculate/buy`, data);
  }
}
