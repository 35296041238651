import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
  IterationStopDatesViewModel,
  TimeSlotsStatus,
} from '@cca-infra/tender-management/v2';
import {
  StopDetailViewModel,
  StopType,
  TimeIndicationType,
} from '@cca-infra/order-management/v2';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { TimeWindowComponent } from '../time-window';
import { PlanningOrderStopViewModel } from '@cca-infra/trip-management/v1';

@Component({
  selector: 'cca-booking-popover-stops-overview',
  imports: [NgxTippyModule, TimeWindowComponent, TranslocoModule],
  providers: [provideTranslocoScope('booking')],
  templateUrl: './booking-popover-stops-overview.component.html',
  styleUrls: ['./booking-popover-stops-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BookingPopoverStopsOverviewComponent {
  stops = input.required<
    | StopDetailViewModel[]
    | IterationStopDatesViewModel[]
    | PlanningOrderStopViewModel[]
  >();
  pickupTimeIndicationType = input<TimeIndicationType>();
  deliveryTimeIndicationType = input<TimeIndicationType>();
  stopsHeader = input.required<string>();
  isAddress = input<boolean>(true);
  isTimesSlotNeededWhenAddress = input<boolean>(false);
  trigger = input<'mouseenter' | 'focus' | 'click' | 'focusin' | 'manual'>(
    'mouseenter',
  );
  zIndex = input<number>(9999);

  body = document.body;
  StopType = StopType;
  TimeSlotsStatus = TimeSlotsStatus;

  stopLabel(stopType: StopType) {
    switch (stopType) {
      case StopType.Pickup:
        return t('booking.generalDialog.Pickup');
      case StopType.Delivery:
        return t('booking.generalDialog.Delivery');
      case StopType.Stop:
        return t('booking.generalDialog.Intermediate');
    }
    return t('booking.generalDialog.Undefined');
  }
}
