import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  inject,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { IconComponent } from '../icon';

type Flavor = 'primary' | 'warning' | 'highlight' | 'danger' | 'default';

@Component({
  selector: 'cca-info-banner',
  imports: [NgClass, IconComponent],
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class InfoBannerComponent {
  private host = inject<ElementRef<HTMLElement>>(ElementRef);

  _flavor: Flavor = 'primary';
  @Input() title = '';
  @Input() description = '';
  @Input() showIcon = true;
  @Input() closable = false;
  @Input() set flavor(value: Flavor) {
    this._flavor = value;
  }
  get flavor(): Flavor {
    return this._flavor;
  }

  close() {
    this.host.nativeElement.remove();
  }
}
