/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Processed  2 = Delivered  3 = Deferred  4 = Bounce  5 = Dropped  6 = SpamReport
 */
export enum EmailStatus {
  Undefined = 0,
  Processed = 1,
  Delivered = 2,
  Deferred = 3,
  Bounce = 4,
  Dropped = 5,
  SpamReport = 6,
}
