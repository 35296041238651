/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NoPreference  1 = NotOnWeekends  2 = NotOnSaturdays  3 = NotOnSundays
 */
export enum PickupDeliveryPreferenceType {
  NoPreference = 0,
  NotOnWeekends = 1,
  NotOnSaturdays = 2,
  NotOnSundays = 3,
}
