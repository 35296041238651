import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CtrlChainIcons } from '@cca-common/core';
import { MatButtonModule } from '@angular/material/button';
import { UiLabelBadgeComponent } from '../badges';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-vertical-action-card',
  templateUrl: './vertical-action-card.component.html',
  styleUrls: ['./vertical-action-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, UiLabelBadgeComponent, IconComponent],
})
export class VerticalActionCardComponent {
  @Input({ required: true }) tagName!: string;
  @Input() description: string | undefined;
  @Input() buttonFormId!: string;
  @Input({ required: true }) tagColor:
    | 'primary'
    | 'warning'
    | 'danger'
    | 'highlight' = 'primary';
  @Input({ required: true }) buttonText!: string;
  @Input({ required: true }) buttonColor: 'primary' | 'warn' | 'accent' =
    'primary';
  @Input({ required: true }) buttonIcon!: CtrlChainIcons;
  @Input() buttonType: 'stroked' | 'flat' = 'stroked';
  @Output() buttonClick = new EventEmitter();
}
