/**
 * Legal Comliance API
 * <p>The Legal Compliance API is used to manage Contractual Agreements.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincargo.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Grouping of the legal document  0 = Undefined  1 = Shipper  2 = Carrier  3 = PlatformUser
 */
export enum LegalDocumentGroupingType {
  Undefined = 0,
  Shipper = 1,
  Carrier = 2,
  PlatformUser = 3,
}
