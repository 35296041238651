import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkCurrencyPipe } from '@cca-common/cdk';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'cca-booking-service-row',
  imports: [MatCheckboxModule, CdkCurrencyPipe],
  templateUrl: './booking-service-row.component.html',
  styleUrls: ['./booking-service-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingServiceRowComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() instructions: string | undefined = '';
  @Input() image = '';
  @Input() price: number | null | undefined = null;
  @Input() includedText = '';
  @Input() currency: string | null | undefined;

  private _checked = false;
  @Input() set checked(checked: string | null | boolean | undefined) {
    this._checked = coerceBooleanProperty(checked);
  }
  get checked() {
    return this._checked;
  }

  @Input() disabled = false;
  @Input() showCheckMark = true;

  @Output() serviceChanged = new EventEmitter<boolean>();
}
