import { NgClass } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IconComponent } from '../icon';

@Component({
  imports: [
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    IconComponent,
  ],
  selector: 'cca-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent {
  @Output() enter = new EventEmitter<string>();
  @Output() searchChanged = new EventEmitter<string>();
  @Output() searchCleared = new EventEmitter<void>();

  @Input() set searchValue(searchValue: string | undefined | null) {
    this.searchField.setValue(searchValue ?? '', { emitEvent: false });
  }
  @Input() searchLabel = '';

  searchField = new FormControl('');

  constructor() {
    this.searchField.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => this.searchChanged.emit(value ?? ''));
  }

  clearSearch() {
    this.searchField.setValue('');
    this.searchCleared.emit();
  }
}
